import React, {Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Navigate, useNavigate } from "react-router-dom";
import Card from '../UI/Card'
import Input from '../UI/Input'
import Button from '../UI/Button';
import classes from './UserLogin.module.css';
import axios from 'axios';

const UserLogin = (props) =>{
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [token, setToken] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

useEffect(()=>{

  let data = new FormData();

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/verify',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
    };

    axios.request(config)
    .then((response) => {
      console.log(response.data)
      if (response.data.message==="Success"){
        setToken(true);
        setName(cookies.Name);
      };
    })
    .catch((error) => {
      console.log(error);
    });
},[])


  // contact@artivelab.com
  // Artive123Lab!@#
  const handleEmail = (event) =>{
    setEmail(event.target.value);
  }
  const handlePassword = (event) =>{
    setPassword(event.target.value);
  }
  const loginFunction = () =>{
  

  axios.post('https://api.orders.e-interior.gr/api/login',{
    email: email,
    password: password
  })
  .then(function (response) {
    setName(response.data["name"])
    setToken(response.data["access_token"]);
    // console.log("Saved data "+ response.data["access_token"] + " " + response.data["name"]);
    setCookies('Name',response.data["name"],{path:'/'});
    setCookies('Token',response.data["access_token"],{path: '/'});
    setEmail('');
    setPassword('');
    localStorage.setItem("isLoggedIn", 1);
    navigate("/");
  })
  .catch(function (error) {
    console.log(error);
  });

  
}
const logoutFunction = () =>{


  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/logout',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data);
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    setName('');
    setToken(false);
  })
  .catch((error) => {
    console.log(error);
  });
  
}

const clearFunction = () =>{


  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/adminfix',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });
  
}

if (!token){
  return(
      <Card>
        <div className={classes.login}>Είσοδος</div>
        <div className={classes.credentials}>
        <Input
        onChange={handleEmail}
        label="Όνομα Χρήστη:"
        input={{
          id: 1,
          type: 'text',
        }}
        vert="true"
         />
        <Input
        onChange={handlePassword}
        label="Κωδικός Πρόσβασης:"
        input={{
          id: 2,
          type: 'text'
        }}
        vert="true"
        />
         </div>
        <span className={classes.loginButton}><Button onClick={loginFunction}>Σύνδεση</Button></span>
      </Card>
)
}
if (token){
  return(
    <Card>
        <div className={classes.login}>{"Έχετε συνδεθεί ως "+name}</div>
        <div className={classes.buttonSet}>
          <span className={classes.loginButton}><Button onClick={logoutFunction}>Αποσύνδεση</Button></span>
          <span className={classes.clearButton}><Button onClick={clearFunction}>Καθαρισμός E-shop</Button></span>
        </div>
      </Card>
    )
}
}

export default UserLogin;

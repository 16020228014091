import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faCheck, faStar} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import isLoggedIn from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import Input from '../UI/Input';
import classes from './SingleAtTailor.module.css'
import axios from 'axios';

//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import CustomProductView from '../ViewProducts/CustomProductView'
import DiadromosView from '../ViewProducts/DiadromosView'

const view = <FontAwesomeIcon icon={faEye} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const check = <FontAwesomeIcon icon={faCheck} />
const star = <FontAwesomeIcon icon={faStar}/>

const SingleAtTailor = (props) =>{
  const [ready,setReady] = useState(0);

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderDate, setOrderDate] = useState('');
  const [viewModal, setViewModal]=useState('');
  const [modalShow, setModalShow] = useState(false);
  const [productStatus, setProductStatus]=useState('');
  const [status,setStatus] = useState(props.status);
  const [statusChange, setStatusChange]=useState("");
  const [dayDiff, setDayDiff] = useState(0)
  const [order,setOrder]=useState('');
  const [orderExtras,setOrderExtras]=useState({paradosi:"",metrisi:"",topothetisi:"",rafis:"",sideroma:""});
  const [orderProducts, setOrderProducts]=useState([]);
  const [orderPayment,setOrderPayment]=useState({paymentType:"",metrita:"",karta:"",synolo:"",prokatavoli:""});
  const [orderDetailed, setOrderDetailed]=useState([{}])
  const [updated, setUpdated]=useState(false);
  const [updateCount, setUpdateCount]=useState(0);
  const [editSewingClick, setEditSewingClick]=useState(false);
  const [editShippingClick, setEditShippingClick]=useState(false);
  const [sewingPrice, setSewingPrice]=useState(JSON.parse(props.expenses).sewing?JSON.parse(props.expenses).sewing:0);
  const [shippingPrice, setShippingPrice]=useState(JSON.parse(props.expenses).shipping?JSON.parse(props.expenses).shipping:0);
  const [editPlaceClick, setEditPlaceClick] = useState(false);
  const [tailorName, setTailorName] = useState(["ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ","ΑΡΗΣ ΣΙΟΥΤΗΣ","ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ","ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ"].includes(props.tailorName)?props.tailorName:(props.tailorName===null)?'':'ΑΛΛΟ');
  const [unlistedTailor, setUnlistedTailor] = useState(props.tailorName);

  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
    setIsLoaded(true);
    console.log(props.clientName);
    console.log(props.tailorName);
  }, []);
   






    const viewClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }else if (productType.includes("roller")){
      setViewModal(<RollerView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")){
      setViewModal(<XaliView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanView id={id} client_name = {props.clientName} onClose={viewCloseHandler} /> )
    }else if (productType.includes('Διάδρομος')){
      setViewModal(<DiadromosView id={id} client_name = {props.clientName} onClose={viewCloseHandler} />)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductView id={id} client_name = {props.clientName} onClose={viewCloseHandler}  />)
    }
  }
  const viewCloseHandler = ()=>{
    setViewModal('');
  }
    
  const sewingPriceChangeHandler =(event)=>{
    setSewingPrice(event.target.value);
  }
  const shippingPriceChangeHandler =(event)=>{
    setShippingPrice(event.target.value);
  }
  const handleChange = (event) =>{
    setOrderDate(event.target.value);
  }

  const handleViewClick = () =>{
    setModalShow(true);
    props.modalShow({modal:true, id:props.order_id,fullName:props.clientName, status:props.status, scrollPos:window.scrollY});
    setStatus(props.status)
  }
 

  const rafeioNoteChangeHandler = (event) =>{
    setTailorName(event.target.value);
  }
  const unlistedTailorChangeHandler = (event) =>{
    setUnlistedTailor(event.target.value);
  }
  
    return(

      <Fragment>
      {viewModal}
        <tr className={`${classes.products} ${dayDiff>=7&&dayDiff<10 ? classes.seven:''} ${dayDiff>=10 ? classes.ten:''}`}>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.order_id}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.vip === 1 &&(<span>{star}</span>)}{props.clientName}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.code} - {props.product_type}{props.quantity&&" x "+props.quantity}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{sewingPrice>0 ? sewingPrice+'€':' '} </td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{shippingPrice>0 ? shippingPrice+'€':' '} </td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{tailorName}</td>
          <td onClick={()=>viewClickHandler(props.id,props.product_type)}>{props.status_updated_at}</td>
          <td onClick={handleViewClick}>{view}</td>
        </tr>

      </Fragment>)
  }
  
  
  

export default SingleAtTailor;

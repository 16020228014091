import React, { useState, Fragment, useEffect } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import classes from "./PickDate.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faSave } from "@fortawesome/free-solid-svg-icons";

const PickDate = (props) =>{
	const [selectedDate, setSelectedDate] = useState(() => {
	    if (props.curDate) {
	      const localDate = new Date(props.curDate);
	      localDate.setHours(localDate.getHours() + 2);
	      const isoDate = localDate.toISOString().slice(0, 10); 
	      return isoDate; 
	    }
	    return "";
	});

	const [nowLoading, setNowLoading] = useState(false);

	const handleSave = () => {
		props.onDateSave({id:props.id, date:selectedDate});
   		setNowLoading(true);
  	};

  	useEffect(()=>{
  		console.log(props.curDate);

  	},[selectedDate])

  	return(
		<Fragment>
	      {!nowLoading ? (
	        <Modal className={classes.singleModal}>
	          <button className={classes.close} onClick={props.onClose}>Πίσω</button>
	          <p>Επιλέξτε ημερομηνία</p>
	          <input
	            type="date"
	            value={selectedDate}
	            onChange={(e) => setSelectedDate(e.target.value)}
	            className={classes.dateInput}
	          />
	          <div className={classes.buttonGrid}>
	            <Button className={classes.confirmation} onClick={handleSave} disabled={!selectedDate}>
	              <FontAwesomeIcon icon={faSave} /> Αποθήκευση
	            </Button>
	          </div>
	        </Modal>
	      ) : (
	        <Modal className={`${classes.singleModal} ${classes.loadingModal}`}>
	          <FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
	        </Modal>
	      )}
	    </Fragment>
	)
}

export default PickDate;
import React,{Fragment,useState,useEffect, useMemo} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faEye, faComment, faCheck, faTrash, faBook, faCartPlus, faStar, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import {useCookies} from 'react-cookie';
import ViewOrderInProgress from './ViewOrderInProgress'
import OrderChangeHistoryView from './OrderChangeHistoryView'
import classes from './SingleOrderInProgress.module.css'
import axios from 'axios';

const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const view = <FontAwesomeIcon icon={faEye} />
const communicate = <FontAwesomeIcon icon={faComment}/>
const check = <FontAwesomeIcon icon={faCheck} />
const trash = <FontAwesomeIcon icon={faTrash} />
const history = <FontAwesomeIcon icon={faBook} />
const submit = <FontAwesomeIcon icon={faCartPlus} />
const star = <FontAwesomeIcon icon={faStar}/>
const warning = <FontAwesomeIcon icon={faTriangleExclamation} />;

const SingleOrderInProgress = (props) =>{
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
	const [status,setStatus] = useState(props.status);
  const [statusChange, setStatusChange] = useState(props.statusChange);
	const [statusText,setStatusText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [singleRender, setSingleRender] = useState('');
  const [click, setClick] = useState(false);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [historyView, setHistoryView] = useState("")
  // const [balance, setBalance] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0);

  const isValidJson = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};
  // useEffect(() => {
  //     setIsLoaded(true);
  // }, []);
  const viewClick = () =>{
    setModalShow(true);
    props.modalShow({modal:true, id:props.id,fullName:props.fullName, status:props.status, scrollPos:window.scrollY});
    setStatus(props.status)
  }
  const communicateClick = () =>{
    props.communicateClick({id:props.id});
    setClick(!click);
  } 
  const checkClick = () =>{
    props.checkClick({id:props.id});
    setClick(!click);
  }
  const submitClick = () =>{
    props.submitClick({id:props.id});
    setClick(!click);
  }
  const cancelClick = () =>{
    props.cancelClick({id:props.id})
  }
  const historyClick = () =>{
  setHistoryView(<OrderChangeHistoryView id={props.id} onClose={onCloseHandler}/>)
  }
  const onCloseHandler = () =>{
    setHistoryView("");
  }
useEffect(()=>{
  switch(props.status) {
    case "not_submitted_yet":
      setStatusText("Δημιουργήθηκε");
      break;
    case "proxeiro":
      setStatusText("Προσφορά")
      break;
    case "submitted":
      setStatusText("Καταχωρήθηκε");
      break;
    case "preparing":
      setStatusText("Ετοιμάζεται");
      break;
    case "repairing":
      setStatusText("Επιδιόρθωση");
      break;  
    case "modifying":
      setStatusText("Ρέλι/Μεταποίηση");
      break;
    case "ready":
      setStatusText("Όλα έτοιμα");
      break;
    case "communicated":
      setStatusText("Ειδοποιήθηκε");
      break;
    case "completed":
      setStatusText("Ολοκληρωμένη");
      break;
    case "canceled":
      setStatusText("Ακυρωμένη");
      break;
    case "ypoloipo":
      setStatusText("Εκκρεμεί υπόλοιπο!")
      break;
    case "arn-ypoloipo":
      setStatusText("Οφείλουμε Ποσό!")
      break;
    default:
      setStatusText(status);
      break;
  }
  },[props.status])

// useEffect(()=>{
//   let paidSum = 0;

//   const paymentDetails = typeof props.payment.detailed === "string"
//     ? JSON.parse(props.payment.detailed)
//     : props.payment.detailed;

//   if (paymentDetails){
//     for (let payment of paymentDetails){
//       if (payment.amount>0){
//        paidSum += Number(payment.amount);
//       }
//     }  
//   }
  
//   setBalance(Math.round((Number(props.totalPrice) - Number(paidSum))*100)/100);
// },[props.totalPrice, props.payment])
const balance = useMemo(() => {
  let paidSum = 0;
  if (props.payment.detailed) {
    const payments = typeof props.payment.detailed === "string"
      ? JSON.parse(props.payment.detailed)
      : props.payment.detailed;
    
    paidSum = payments.reduce((sum, payment) => sum + (payment.amount > 0 ? Number(payment.amount) : 0), 0);
  }
  return Math.round((Number(props.totalPrice) - Number(paidSum)) * 100) / 100;
}, [props.payment, props.totalPrice]);

  useEffect(()=>{
    let expensesCalc = 0;
    if (props.products){
      for (let product of props.products){
        if (product.status!=='deleted'){
          let wholesale = Number(JSON.parse(product.expenses).wholesale);
          let shipping = Number(JSON.parse(product.expenses).shipping);
          let sewing = Number(JSON.parse(product.expenses).sewing);
          expensesCalc += wholesale+shipping+sewing;
        }
    }
    setTotalProfit(Math.round((props.totalPrice-expensesCalc)*100)/100);
    }
    
  },[props.showProfit,props.products])

const formattedStatusDate = useMemo(() => {
  const statusUpdateDate = new Date(props.status_updated_at).toLocaleString().split(',');
  const meridiem = statusUpdateDate[1].split(' ')[2];
  const statusUpdateTime = statusUpdateDate[1].split(':');
  return `${statusUpdateDate[0]} ${statusUpdateTime[0]}:${statusUpdateTime[1]} ${meridiem || ''}`;
}, [props.status_updated_at]);

// useEffect(()=>{
//   const statusUpdateDate = new Date(props.status_updated_at).toLocaleString().split(',');

//   const meridiem = statusUpdateDate[1].split(' ')[2];
//   const statusUpdateTime = statusUpdateDate[1].split(':');
//   const formattedStatusDate = `${statusUpdateDate[0]} ${statusUpdateTime[0]}:${statusUpdateTime[1]} ${meridiem!==undefined ? meridiem:''}`
//   setSingleRender(<tr className={`${classes.orderRow} ${statusText === "Προσφορά" ? classes.proxeiroRow : ''} ${props.showProfit && totalProfit > 0 ? classes.greenRow : ''} ${props.showProfit && totalProfit < 0 ? classes.redRow : ''}`}>
//             <td onClick={viewClick}>{props.id}</td>
//             <td onClick={viewClick}><p>{props.vip === 1 &&(<span>{star}</span>)}{props.warning === 1 &&(<span style={{color:'red'}}>{warning}</span>)}{props.fullName}</p>
//                 <p>{props.phone}</p>
//             </td>
//             <td onClick={viewClick}>{props.notes}</td>
//             <td onClick={viewClick}>{props.totalPrice ? props.totalPrice+'€':'-'}</td>
//             <td onClick={viewClick}>{balance}€</td>
//             <td onClick={viewClick}>
//             <div>
//               <p className={`${(props.status==="ypoloipo"||props.status==="arn-ypoloipo")&&classes.warning} ${(props.status==="repairing"||props.status==="modifying")&&classes.yellowWarning}`}>{statusText}</p>
//               <p className={classes.updateStatusDate}>{props.status_updated_at && formattedStatusDate}
//                 <span title="Ιστορικό" className={classes.historyIcon} onClick={historyClick}>  {history}</span>
//               </p>
//             </div>
//             </td>
//             <td onClick={viewClick}>{props.created_at.split('T')[0]}</td>
//             {props.showProfit&&<td>{totalProfit}€</td>}
//             {statusText==="Όλα έτοιμα"||statusText==="Ειδοποιήθηκε"||statusText==="Εκκρεμεί υπόλοιπο!"?<Fragment>
//             <td title="Επικοινωνία" onClick={communicateClick}>{communicate}</td>
//             <td title="Ολοκλήρωση" onClick={checkClick}>{check}</td>
//             </Fragment>
//             :<Fragment>
//             {statusText=="Προσφορά"?
//             <td onClick={submitClick}>{submit}</td>
//             :<td onClick={viewClick}></td>}
//             <td onClick={viewClick}></td>
//             </Fragment>}
//             <td title="Ακύρωση" onClick={cancelClick}>{trash}</td>
//         </tr>);
// }, [statusText, props.modalShow])



  return(
    <Fragment>
      {historyView}
      <tr className={`${classes.orderRow} ${statusText === "Προσφορά" ? classes.proxeiroRow : ''} ${props.showProfit && totalProfit > 0 ? classes.greenRow : ''} ${props.showProfit && totalProfit < 0 ? classes.redRow : ''}`}>
        <td onClick={viewClick}>{props.id}</td>
        <td onClick={viewClick}>
          <p>{props.vip === 1 && <span>{star}</span>}{props.warning === 1 && <span style={{color:'red'}}>{warning}</span>}{props.fullName}</p>
          <p>{props.phone}</p>
        </td>
        <td onClick={viewClick}>{props.notes}</td>
        <td onClick={viewClick}>{balance}€</td>
        <td onClick={viewClick}>
          <div>
            <p className={`${(props.status === "ypoloipo" || props.status === "arn-ypoloipo") && classes.warning} ${(props.status === "repairing" || props.status === "modifying") && classes.yellowWarning}`}>{statusText}</p>
            <p className={classes.updateStatusDate}>
              {formattedStatusDate}
              <span title="Ιστορικό" className={classes.historyIcon} onClick={historyClick}>{history}</span>
            </p>
          </div>
        </td>
        <td onClick={viewClick}>{props.created_at.split('T')[0]}</td>
        {props.showProfit && <td>{totalProfit}€</td>}
        {["Όλα έτοιμα", "Ειδοποιήθηκε", "Εκκρεμεί υπόλοιπο!"].includes(statusText) ? (
          <>
            <td title="Επικοινωνία" onClick={communicateClick}>{communicate}</td>
            <td title="Ολοκλήρωση" onClick={checkClick}>{check}</td>
          </>
        ) : (
          <>
            {statusText === "Προσφορά" ? <td onClick={submitClick}>{submit}</td> : <td onClick={viewClick}></td>}
            <td onClick={viewClick}></td>
          </>
        )}
        <td title="Ακύρωση" onClick={cancelClick}>{trash}</td>
      </tr>
    </Fragment>
    )
}

export default SingleOrderInProgress
import React,{useState,useEffect,Fragment,useRef} from 'react';
import Modal from '../UI/Modal';
import classes from './ViewOrderInProgress.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../UI/Button';
import logo from '../../einterior-logo.png'
import Input from '../UI/Input'
import CompleteOrder from './CompleteOrder'
import ClientView from '../Clients/ClientView'
import { faPencilAlt, faSave, faEye, faTrash, faCopy, faPlus, faBook, faPrint, faComment, faX, faStar, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import {useCookies} from 'react-cookie';
import axios from 'axios';
import moment from 'moment';
import ProductChangeHistoryView from './ProductChangeHistoryView'
import NotifyClient from './NotifyClient'
import DeletePayment from './DeletePayment'
import EditTasks from './EditTasks'
import PickDate from './PickDate'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";

//New products
import CurtainRod from '../NewOrder/NewOrderProducts/CurtainRod';
import Fabrics from '../NewOrder/NewOrderProducts/Fabric';
import Persides from '../NewOrder/NewOrderProducts/Persides';
import Roller from '../NewOrder/NewOrderProducts/Roller';
import XaliMoketaPlastiko from '../NewOrder/NewOrderProducts/XaliMoketaPlastiko';
import RomanPseudoroman from '../NewOrder/NewOrderProducts/RomanPseudoroman';
import Diadromos from '../NewOrder/NewOrderProducts/Diadromos';
import CustomProduct from '../NewOrder/NewOrderProducts/CustomProduct';


//view products
import FabricView from '../ViewProducts/FabricView'
import CurtainRodView from '../ViewProducts/CurtainRodView'
import PersidesView from '../ViewProducts/PersidesView'
import RollerView from '../ViewProducts/RollerView'
import XaliView from '../ViewProducts/XaliView'
import RomanView from '../ViewProducts/RomanView'
import DiadromosView from '../ViewProducts/DiadromosView'
import CustomProductView from '../ViewProducts/CustomProductView'

//edit products
import CurtainRodEdit from '../EditProducts/CurtainRodEdit'
import FabricEdit from '../EditProducts/FabricEdit'
import PersidesEdit from '../EditProducts/PersidesEdit'
import RollerEdit from '../EditProducts/RollerEdit'
import RomanEdit from '../EditProducts/RomanEdit'
import XaliEdit from '../EditProducts/XaliEdit'
import DiadromosEdit from '../EditProducts/DiadromosEdit';
import CustomProductEdit from '../EditProducts/CustomProductEdit';

const view = <FontAwesomeIcon icon={faEye} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const copy = <FontAwesomeIcon icon={faCopy} />
const trash = <FontAwesomeIcon icon={faTrash} />
const plus = <FontAwesomeIcon icon={faPlus} />
const history = <FontAwesomeIcon icon={faBook} />
const print = <FontAwesomeIcon icon={faPrint} />
const xsign = <FontAwesomeIcon icon={faX} />
const communicate = <FontAwesomeIcon icon={faComment}/>
const star = <FontAwesomeIcon icon={faStar}/>
const warning = <FontAwesomeIcon icon={faTriangleExclamation} />;

const ViewOrderInProgress = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const textareaRef = useRef(null);
  const [order,setOrder]=useState('');
  const [orderExtras,setOrderExtras]=useState({paradosi:"",metrisi:"",topothetisi:"",rafis:"",sideroma:""});
  const [orderPayment,setOrderPayment]=useState({paymentType:"",metrita:"",karta:"",synolo:"",prokatavoli:""});
  const [orderDetailed, setOrderDetailed]=useState([{}])
  const [balance, setBalance]=useState()
  const [viewModal, setViewModal]=useState('');
  const [editModal, setEditModal]=useState('');
  const [updatedProduct, setUpdatedProduct]=useState('');
  const [statusChange, setStatusChange]=useState("");
  const [selection,setSelection]=useState('');
  const [modalShow, setModalShow]=useState(false);
  const [makingPayment, setMakingPayment]=useState(false);
  const [paymentSelection, setPaymentSelection]=useState('metrita');
  const [paymentInput, setPaymentInput]=useState('');
  const [totalPriceEdit, setTotalPriceEdit]=useState(false);
  const [totalPriceVal, setTotalPriceVal]=useState('');
  const [updateCount, setUpdateCount]=useState(0);
  const [balanceCount, setBalanceCount]=useState(0);
  const [historyView, setHistoryView]=useState("");
  const [client, setClient]=useState("");
  const [totalPayment, setTotalPayment]=useState('')
  const [wayOfPayment, setWayOfPayment]=useState('')
  const [updatedOrder, setUpdatedOrder]=useState('');
  const [communicateAvailable, setCommunicateAvailable]=useState('');

  const [totalProfit, setTotalProfit]=useState(0);
  const [profitUpdate,setProfitUpdate]=useState(false);

  const [notifyModal, setNotifyModal]=useState('');
  const [deletePaymentModal, setDeletePaymentModal]=useState('');
  const [completeModal, setCompleteModal]=useState('');
  const [editTasksModal, setEditTasksModal]=useState('');
  const [clientModal, setClientModal]=useState('');
  const [deliveryDateModal, setDeliveryDateModal]=useState('');

  const [orderProducts, setOrderProducts] = useState([])
  const [notes, setNotes]=useState('');

  const [rafeioNote, setRafeioNote] = useState([])
  const [unlistedTailor, setUnlistedTailor] = useState([])

  const [fabricChecked, setFabricChecked] = useState(false);
  const [mechanicChecked, setMechanicChecked] = useState(false);

  const [totalUpdate, setTotalUpdate] = useState(false);
  const [productChange, setProductChange] = useState(false); 

  const [showDeliverDate, setShowDeliverDate] = useState("");
  const [deliverMessage, setDeliverMessage] = useState("");
  const [deliverClass, setDeliverClass] = useState("");

  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };

    axios.post('https://api.orders.e-interior.gr/api/order/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setOrder(response.data)
      setUpdatedOrder(response.data)
      setOrderExtras(JSON.parse(response.data.extras));
      setOrderProducts(response.data.products)
      setOrderPayment(JSON.parse(response.data.payment));
      setShowDeliverDate(response.data.deliver_date);
      let details = JSON.parse(response.data.payment).detailed;
      setOrderDetailed(JSON.parse(details));
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    })
    },[statusChange,modalShow,editModal,makingPayment,fabricChecked,mechanicChecked,updatedProduct,deletePaymentModal]);

  useEffect(() => {
    if (order && productChange) {
      recalculateTotals();
      setProductChange(false);
    }
  }, [order]);

  useEffect(()=>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };

    axios.post('https://api.orders.e-interior.gr/api/order/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setOrder(response.data)
      setUpdatedOrder(response.data)
      setOrderExtras(JSON.parse(response.data.extras));
      setOrderProducts(response.data.products)
      setOrderPayment(JSON.parse(response.data.payment));
      let details = JSON.parse(response.data.payment).detailed;
      setOrderDetailed(JSON.parse(details));
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    })

  },[totalUpdate])

  useEffect(()=>{
    let expensesCalc = 0;
    if (order.products){
      for (let product of order.products){
        if (product.status!=='deleted'){
          let wholesale = JSON.parse(product.expenses).wholesale?Number(JSON.parse(product.expenses).wholesale):0;
          let shipping = JSON.parse(product.expenses).shipping?Number(JSON.parse(product.expenses).shipping):0;
          let assistant = JSON.parse(product.expenses).assistant? Number(JSON.parse(product.expenses).assistant):0;
          let sewing = JSON.parse(product.expenses).sewing?Number(JSON.parse(product.expenses).sewing):0;
          expensesCalc += wholesale+shipping+sewing+assistant;
        }
    }
    setTotalProfit(Math.round((orderPayment.synolo-expensesCalc)*100)/100);
    }


  },[order,updatedProduct])

  useEffect(() => {
    if (order){
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/client/'+order.client_id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setClient(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    })
  }
  },[order])

  useEffect(()=>{
    setNotes(orderExtras.notes?orderExtras.notes:'')
  },[orderExtras])

  useEffect(()=>{
    setFabricChecked()
    setMechanicChecked()
  },[order])
  
useEffect(()=>{
  if (textareaRef.current) {
        // Reset the height to auto to calculate the new height correctly
        textareaRef.current.style.height = 'auto';
        // Set the height to the scrollHeight to expand the textarea
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    },[notes])
  
const [groupedData, setGroupedData] = useState([]);
const [pagesHeight, setPagesHeight] = useState(0);
const [borderMargin, setBorderMargin] = useState(0);



  
  useEffect(()=>{
    if (order){
      let activeProducts = order.products.filter(product => product.status !== 'deleted').length
      let orderSets = 0;

      if (activeProducts>=12){
        orderSets++;
        activeProducts-=12;
      }

      while (activeProducts>=16) {
        orderSets++;
        activeProducts-=16;
      }

      if (activeProducts>6){
        orderSets++;
        activeProducts-=6;
      }


      if (orderSets===0&&activeProducts>3){
        orderSets++;
      }
      


      if (orderSets>0){
        setPagesHeight(-297*(orderSets));
      }else{
        setPagesHeight(0);
      }
    }
  },[order])



  const handlePrintClick = () => {
    window.print()
  };

  const handleBack = () =>{
    if (balance===0&&(updatedOrder.status==="ready"||updatedOrder.status==="communicated")){
        setCompleteModal(<CompleteOrder id={props.id} yesClick={handleBackYes} noClick={handleBackNo}/>)
    }else{
      props.onClose();
    }
    
  }
  const handleBackYes = (e) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      status: "completed"
      },
      config)
    .then(function (response) {
      setCompleteModal('');
      props.onClose();
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }
  const handleBackNo = (e) =>{
    setCompleteModal('');
    props.onClose();
  }
  const selectChangeHandler = (event) =>{
    setSelection(event.target.value);
  }
  const paymentSelectionHandler = (event)=>{
    setPaymentSelection(event.target.value);

  }
  const paymentInputChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setPaymentInput(value);
  }
  const addClickHandler = () =>{
    if (selection !== "0"){
    setModalShow(true);
  }
  }
  const addCloseHandler = () =>{
    setModalShow(false);
    
  }
  const addSaveHandler = (event) =>{
    setModalShow(false);
    setUpdatedProduct(event.data)

  }
  const notesChangeHandler = (event) =>{
    setNotes(event.target.value);
  }

  const totalEditClick = () =>{
    setTotalPriceEdit(true);
    setTotalPriceVal(orderPayment.synolo);
  }
  const totalPriceValChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    setTotalPriceVal(value);
  }
  const totalSaveClick = () =>{
    setTotalPriceEdit(false);
    let paymentsUpdate = orderPayment;
    paymentsUpdate.synolo = totalPriceVal;
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      payment: JSON.stringify(paymentsUpdate),
      },
      config)
    .then(function (response) {
      setUpdatedProduct(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }

  const wholesalePriceChangeHandler = (event,id) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    const updatedProducts = order.products.map(product => {
      if (product.id === id) {
        const expensesObj = JSON.parse(product.expenses);
        expensesObj.wholesale = value;
        const newExpensesJSON = JSON.stringify(expensesObj);
        return { ...product, expenses: newExpensesJSON, wholesaleChange:true };
      }
      return product;
    });

    const updatedData = { ...order, products: updatedProducts };
    setOrder(updatedData);

  }

  const shippingPriceChangeHandler = (event,id) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    const updatedProducts = order.products.map(product => {
      if (product.id === id) {
        const expensesObj = JSON.parse(product.expenses);
        expensesObj.shipping = value;
        const newExpensesJSON = JSON.stringify(expensesObj);
        return { ...product, expenses: newExpensesJSON, wholesaleChange:true };
      }
      return product;
    });

    const updatedData = { ...order, products: updatedProducts };
    setOrder(updatedData);

  }

    const assistantPriceChangeHandler = (event,id) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    const updatedProducts = order.products.map(product => {
      if (product.id === id) {
        const expensesObj = JSON.parse(product.expenses);
        expensesObj.assistant = value;
        const newExpensesJSON = JSON.stringify(expensesObj);
        return { ...product, expenses: newExpensesJSON, wholesaleChange:true };
      }
      return product;
    });

    const updatedData = { ...order, products: updatedProducts };
    setOrder(updatedData);

  }

  const sewingPriceChangeHandler = (event,id) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
        const updatedProducts = order.products.map(product => {
      if (product.id === id) {
        const expensesObj = JSON.parse(product.expenses);
        expensesObj.sewing = value;
        const newExpensesJSON = JSON.stringify(expensesObj);
        return { ...product, expenses: newExpensesJSON, wholesaleChange:true };
      }
      return product;
    });

    const updatedData = { ...order, products: updatedProducts };
    setOrder(updatedData);
  }
  const handleWholesaleSave = (id,expenses, product_options) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      let profit=0;
      let expensesTotal = Number(JSON.parse(expenses).wholesale) + Number(JSON.parse(expenses).shipping) + Number(JSON.parse(expenses).assistant)+ Number(JSON.parse(expenses).sewing);
      if (JSON.parse(product_options).discountPrice){
        profit = (JSON.parse(product_options).discountPrice * JSON.parse(product_options).quantity) - expensesTotal
      }else{
        profit = JSON.parse(product_options).totalPrice - expensesTotal
      }
      profit = Math.round(profit*100)/100;
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        expenses: expenses,
        profit: profit,
        },
        config)
      .then(function (response) {
        const updatedProducts = order.products.map(product => {
        if (product.id === response.data.product.id) {
          return { ...product, profit: profit, wholesaleChange:false };
        }
         return product;
        });
        const updatedData = { ...order, products: updatedProducts };
        // console.log(updatedData)
        setOrder(updatedData);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    
  }

  const handleViewOrderClick = (id) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status:"ordered",
        ordered_at: moment().format('YYYY-MM-DD')
        },
        config)
      .then(function (response) {
        setStatusChange(response.data)
        setViewModal('');
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
      
  }

  const viewClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("roller")){
      setViewModal(<RollerView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")||productType.includes("Σετ Κρεβατοκάμαρας")){
      setViewModal(<XaliView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/> )
    }else if (productType.includes('Διάδρομος')){
      setViewModal(<DiadromosView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler} onOrdered={()=>handleViewOrderClick(id)}/>)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductView id={id} client_name = {client.first_name+' '+client.last_name} onClose={viewCloseHandler}  onOrdered={()=>handleViewOrderClick(id)}/>)
    }
  }
  
  const viewCloseHandler = ()=>{
    setViewModal('');
  }
  
  const newPaymentHandler = ()=>{
    setMakingPayment(true);
  }
  const cancelPaymentHandler = ()=>{
    setMakingPayment(false);
  }
  const savePaymentHandler = ()=>{
    if (paymentInput>0){
      let detailed = orderDetailed;
      let currDate = new Date();
      detailed.push({
        type:"pliromi",
        amount:paymentInput, 
        how:paymentSelection,
        date: currDate
        })
      
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
        };
        axios.post('https://api.orders.e-interior.gr/api/order/update',{
          key: cookies.Token,
          order_id: props.id,
          payment:JSON.stringify({...orderPayment, detailed:JSON.stringify(detailed)})

          },
          config)
        .then(function (response) {
          setMakingPayment(false);
          setPaymentInput('');
          setPaymentSelection('metrita');
          setUpdatedOrder(response.data.order)
        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
          console.log(error);
        });
      }
    }

  const handleDeletePaymentClick = (formattedDate, type, how, amount)=>{
    setDeletePaymentModal(<DeletePayment id={props.id} date={formattedDate} type={type} how={how} amount={amount} yesClick={handleDeletePaymentYes} noClick={handleDeletePaymentNo} />)
  }
  
  const handleDeletePaymentYes = (props) =>{
    const parts = props.date.split('/');
    const day = parseInt(parts[0]) + 1;
    const month = parseInt(parts[1]) - 1 ; // Months are zero-based in JavaScript
    const year = parseInt(parts[2]);

    const date = new Date(year, month, day);

    const isoDate = date.toISOString().split('T')[0];

    
    let detailed = orderDetailed;

    detailed = detailed.filter(obj => !(obj.date.split('T')[0] === isoDate && obj.type === props.type && obj.how === props.how && obj.amount === props.amount));
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
    };

    axios.post('https://api.orders.e-interior.gr/api/order/update',{
          key: cookies.Token,
          order_id: props.id,
          payment:JSON.stringify({...orderPayment, detailed:JSON.stringify(detailed)})

          },
          config)
        .then(function (response) {
          setDeletePaymentModal('');
        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
          console.log(error);
        });
      
  }
  const handleDeletePaymentNo = () =>{
    setDeletePaymentModal('');
  }
  const saveNotesHandler = () =>{
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
        };
    let extrasUpdate = orderExtras;
    extrasUpdate.notes = notes;
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      extras: JSON.stringify(extrasUpdate),
      },
      config)
    .then(function (response) {
      setUpdatedProduct(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

  }

   const editClickHandler = (id,productType) =>{
      if (productType.includes("Ύφασμα")){
      setEditModal(<FabricEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes("Κουρτινόξυλο")){
        setEditModal(<CurtainRodEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes("Περσίδες")){
        setEditModal(<PersidesEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes("roller")){
        setEditModal(<RollerEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")||productType.includes("Σετ Κρεβατοκάμαρας")){
        setEditModal(<XaliEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
        setEditModal(<RomanEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes('Διάδρομος')){
        setEditModal(<DiadromosEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }else if (productType.includes('Άλλο είδος')){
      setEditModal(<CustomProductEdit id={id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
      }
  }
  
  const editCloseHandler = ()=>{
    setEditModal('');
  }
  const editSaveHandler = (event) =>{
    setEditModal('');
    setUpdatedProduct(event.data);
    setProductChange(true);
  }
  const copyClickHandler = (id) =>{
        const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/'+id,{
        key: cookies.Token},
        config
      )
      .then(function (response) {
        // console.log(response.data)
        let productInfo = response.data;
        let productOptions = response.data.product_options;
        axios.post('https://api.orders.e-interior.gr/api/product/create',{
        key: cookies.Token,
        order_id: props.id,
        product_type: productInfo.product_type,
        manufacturer: productInfo.manufacturer,
        code: productInfo.code,
        product_options: productOptions,
        status: "submitted",
        },
        config
      )
        .then(function (response) {
          setUpdatedProduct(response.data)
          
        })
        .catch(function (error) {
          console.log(error);
        });
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });

      axios.post('https://api.orders.e-interior.gr/api/order/update',{
        key: cookies.Token,
        order_id: props.id,
        status: "submitted"
        },
        config)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      setProductChange(true);
  }
  const deleteClickHandler = (id, status) =>{
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    if (status!=="deleted"){
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status: "deleted",
        },
        config
      )
      .then(function (response) {
        // console.log(response.data);
        setUpdatedProduct(response.data)
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    }else{
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status: "submitted",
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        setUpdatedProduct(response.data)
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    }
    setProductChange(true);
  }
  useEffect(()=>{
    let paymentSum=0;
    for (const o of orderDetailed){
      paymentSum+=Number(o.amount);
    }
    setTotalPayment(Number(paymentSum));
    setBalance(Math.round((Number(orderPayment.synolo)-Number(paymentSum))*100)/100);
  },[orderDetailed,makingPayment, updatedProduct, deletePaymentModal, updatedOrder])

  // useEffect(()=>{
  //   if (updatedOrder.status==="ready"||updatedOrder.status==="communicated"){
  //     if (balance===0){
  //       setCompleteModal(<CompleteOrder id={props.id} yesClick={handleCheckYes} noClick={handleCheckNo}/>)
  //     }
  //   }
  //   else{
  //     setBalanceCount(balance+1)
  //   }
  // },[balance])


  const handleCheckYes = (e) =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      status: "completed"
      },
      config)
    .then(function (response) {

      setCompleteModal('')

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }
  const handleCheckNo = (e) =>{
    setCompleteModal('')
  }

  const handleClientView = () =>{
      setClientModal(<ClientView id={order.client_id} onClose={clientViewCloseHandler}/>)
    }
    const clientViewCloseHandler = () =>{
      setClientModal('')
    }


  const handleProductStatusChange = (id,props,event) =>{
    if (event.target.value!=="ordered"){
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status:event.target.value
        },
        config)
      .then(function (response) {
        setStatusChange(response.data)
        
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
     }else{
      const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: id,
        status:event.target.value,
        ordered_at: moment().format('YYYY-MM-DD')
        },
        config)
      .then(function (response) {
        setStatusChange(response.data)
        
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
     } 
  }
 
 const handleRafeioSave = (rafeio, id, unlistedTailor) =>{
  const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
  if (rafeio==="ΑΛΛΟ"){
    rafeio=unlistedTailor;
  }
  axios.post('https://api.orders.e-interior.gr/api/updateProductStatus',{
        key: cookies.Token,
        note: rafeio,
        status_id:id
        },
        config)
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });

 }

const handleFabricCheckboxChange = (fabric, id) =>{
  const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
  axios.post('https://api.orders.e-interior.gr/api/updateProductStatus',{
        key: cookies.Token,
        fabric_ready: fabric.target.checked,
        status_id:id
        },
        config)
      .then(function (response) {
        setFabricChecked(!fabricChecked);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
}

const handleMechanismCheckboxChange = (mechanism, id) =>{
  const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
  axios.post('https://api.orders.e-interior.gr/api/updateProductStatus',{
        key: cookies.Token,
        mechanic_ready: mechanism.target.checked,
        status_id:id
        },
        config)
      .then(function (response) {
        setMechanicChecked(!mechanicChecked);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
}
  useEffect(()=>{
    if (updateCount > 1 && order !== "") {
      if (order.status !== "proxeiro"){
        const config = {
          headers: { Authorization: "Bearer " + cookies.Token },
        };

        axios
          .post(
            "https://api.orders.e-interior.gr/api/order/status",
            {
              key: cookies.Token,
              order_id: props.id,
            },
            config
          )
          .then((response) => {
            setUpdatedOrder(response.data.order);
          })
          .catch((error) => {
            if (error.response?.data?.message === "Unauthenticated.") {
              removeCookies("Name", { path: "/" });
              removeCookies("Token", { path: "/" });
              navigate("/user-login");
            }
            console.error(error);
          });
        
        }
      }
      setUpdateCount(updateCount+1); 
  },[order, statusChange])

  const recalculateTotals = () =>{
    let installTotal = 0;
    let initialTotal = 0;
    let priceTotal = 0;
    let metrisi = JSON.parse(order.extras).metrisi ? parseInt(JSON.parse(order.extras).metrisi):0;
    let paradosi = JSON.parse(order.extras).paradosi ? parseInt(JSON.parse(order.extras).paradosi):0;
    let rafis = JSON.parse(order.extras).rafis ? parseInt(JSON.parse(order.extras).rafis):0;
    let sideroma = JSON.parse(order.extras).sideroma ? parseInt(JSON.parse(order.extras).sideroma):0;  

    const filteredOrder = order.products.filter(function(product){
      return product.status !== "deleted";
    })
    filteredOrder.map((result)=>{

      let singleInstall= JSON.parse(result.product_options).installPrice ? Number(JSON.parse(result.product_options).installPrice):0;
      let singleInitial= JSON.parse(result.product_options).totalPrice ? Number(JSON.parse(result.product_options).totalPrice):0;
      let singlePrice = JSON.parse(result.product_options).totalPriceDiscount ? Number(JSON.parse(result.product_options).totalPriceDiscount):(JSON.parse(result.product_options).discountPrice ? Number(JSON.parse(result.product_options).discountPrice):singleInitial);
      installTotal = singleInstall + installTotal;
      initialTotal = singleInitial + singleInstall + initialTotal;
      priceTotal = singlePrice + singleInstall + priceTotal;
    })
    initialTotal = initialTotal + metrisi + paradosi + rafis + sideroma;
    priceTotal = priceTotal + metrisi + paradosi + rafis + sideroma;
    let extrasUpdate = orderExtras;
    extrasUpdate.topothetisi = Math.round(installTotal*100)/100;
    let paymentsUpdate = orderPayment;
    paymentsUpdate.initialPrice = Math.round(initialTotal*100)/100;
    paymentsUpdate.synolo = Math.round(priceTotal*100)/100;
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      extras: JSON.stringify(extrasUpdate),
      payment: JSON.stringify(paymentsUpdate),
      },
      config)
    .then(function (response) {
      setTotalUpdate(!totalUpdate);
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  }

useEffect(()=>{
  let updateUnlisted = []
  const updatedArray = orderProducts.map(obj =>{

    if (obj.last_status.length !== 0){
    if (!["ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ","ΑΡΗΣ ΣΙΟΥΤΗΣ","ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ","ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ","ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ"].includes(obj.last_status[0].note)&&obj.last_status[0].note!==null){
      updateUnlisted = [...updateUnlisted,{id:obj.id,status:obj.last_status[0].note}];
      return { ...obj,
              last_status:[
              {
                ...obj.last_status[0],
                note: 'ΑΛΛΟ'
              }
              ]};
    }else {
      return obj;
    }
  } else{
    return obj;
  }
  })
    setUnlistedTailor(updateUnlisted)
    setRafeioNote(updatedArray);
  },[orderProducts])



  const rafeioNoteChangeHandler = (event,id)=>{
    const updatedArray = rafeioNote.map(obj => {
        if (obj.id === id) {
          return { ...obj,
                  last_status:[
                    {
                      ...obj.last_status[0],
                      note: event.target.value
                    }
                    ]};
        } else {
          return obj;
        }
      });

    setRafeioNote(updatedArray)
  }

  const historyClick = (id) =>{
    setHistoryView(<ProductChangeHistoryView id={id} onClose={onCloseHandler}/>)
  }
  const onCloseHandler = () =>{
    setHistoryView("");
    setEditTasksModal("");
    setDeliveryDateModal("");
  }
  const communicateClick = () =>{
    setNotifyModal(<NotifyClient id={props.id} yesClick={handleCommunicateYes} noClick={handleCommunicateNo}/>);
  } 
   const handleCommunicateYes = (e) =>{
    
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      status: "communicated"
      },
      config)
    .then(function (response) {
      setNotifyModal('');
      setUpdatedOrder(response.data.order)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  }
  const handleCommunicateNo = (e) =>{
    setNotifyModal('');

  }

  useEffect(()=>{
    if (updatedOrder.status==="ready"){
      setCommunicateAvailable(<span title="Επικοινωνία" onClick={communicateClick}>{communicate}</span>)
    }else{
      setCommunicateAvailable('');
    }
  },[updatedOrder])

    const handleOfferOrder = () =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      status: "proxeiro"
      },
      config)
    .then(function (response) {
      setOrder({...order, status:response.data.order.status})
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  }

  const handleSubmitOrder = () =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: props.id,
      status: "submitted",
      submittedFromProxeiro:true,
      },
      config)
    .then(function (response) {
      setOrder({...order, status:response.data.order.status})
      
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  }

  const unlistedTailorChangeHandler = (event,id) =>{
    let alreadyExists = false;
    const updatedData = unlistedTailor.map((item) => {
      if (item.id === id) {
        alreadyExists = true;
        return { id: id, status: event.target.value }; // Update the status of the matching object
        
      }
      return item; // Return the unchanged object
    });
    if (!alreadyExists){
      updatedData.push({ id: id, status: event.target.value })
      alreadyExists = false;
    }
    setUnlistedTailor(updatedData);
  }
const footerStyle = {
  /*position: 'absolute',
  bottom: `${pagesHeight}mm`,*/
  position: 'static',
  pageBreakInside: 'avoid',
  width: '100%',

};

const handleEditTasksClick = () =>{
  setEditTasksModal(<EditTasks id={props.id} orderExtras={orderExtras} onExtrasUpdate={handleExtrasUpdate} onClose={onCloseHandler}/>);
}
const handleExtrasUpdate = (newExtras) => {
  setEditTasksModal('');
  setOrderExtras(JSON.parse(newExtras));
};

const handleDeliveryDateClick = () =>{
  let curDate = showDeliverDate;
  setDeliveryDateModal(<PickDate id={props.id} curDate={curDate} onDateSave={handleDateSave} onClose={onCloseHandler}/>);
}

const handleDateSave = (newDate) => {
  setDeliveryDateModal('');
   const config = {
    headers: { 'Authorization': 'Bearer ' + cookies.Token }
  };

  axios.post('https://api.orders.e-interior.gr/api/order/update', {
    key: cookies.Token,
    order_id: props.id,
    deliver_date: new Date(newDate.date).toISOString(), 
  }, config)
    .then(function (response) {
      setShowDeliverDate(newDate.date);
    })
    .catch(function (error) {
      if (error.response.data.message === "Unauthenticated.") {
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
}

useEffect(()=>{
  const currentDate = new Date();

  const deliveryDate = new Date(showDeliverDate);


  // Format the current date and delivery date for comparison (YYYY-MM-DD format)
  const currentDateStr = currentDate.toLocaleDateString("el-GR");
  const deliveryDateStr = deliveryDate.toLocaleDateString("el-GR");



  // Compare delivery date with current date
  if (deliveryDateStr > currentDateStr) {
    const daysRemaining = Math.ceil((deliveryDate - currentDate) / (1000 * 60 * 60 * 24));
    setDeliverMessage(`Για την παραγγελία απομένουν ${daysRemaining} μέρες`);

    // Apply class based on daysRemaining
    if (daysRemaining > 5) {
      setDeliverClass(classes.greenClass);  
    } else if (daysRemaining > 1) {
      setDeliverClass(classes.yellowClass); 
    } else {
      setDeliverClass(classes.redClass);   
    }
  } else if (deliveryDateStr < currentDateStr) {
    const daysLate = Math.ceil((currentDate - deliveryDate) / (1000 * 60 * 60 * 24));
    setDeliverMessage(`Η παραγγελία έχει καθυστερήσει ${daysLate} μέρες`);
    setDeliverClass(classes.redClass); 
  } else {
    setDeliverMessage(`Η παραγγελία είναι για σήμερα`);
    setDeliverClass(classes.redClass); 
  }
},[showDeliverDate])


  return(
    <Fragment>
    <div className={classes.printable}>
      <div id={classes.printableClient}>
        <div id={classes.printableClientLeft}><img src={logo} alt="Logo" />{order.status==="proxeiro"&&<p className={classes.printableOffer}>ΠΡΟΣΦΟΡΑ</p>}</div>
        <div id={classes.printableClientRight}>
          <p>Ημερομηνία Παραγγελίας: {new Date(order.created_at).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric',})}</p>
          <p>Ονοματεπώνυμο: {props.fullName}</p>
          {client.industry&&<p>Επάγγελμα: {client.industry.length<101 ? client.industry:(client.industry.slice(0,100)+'...')}</p>}
          <p>Τηλ: {client.phone}</p>
          <p>Διεύθυνση: {client.address}  -  Πόλη: {client.city}</p>
          <p>Α.Φ.Μ.: {client.afm}  -  Δ.Ο.Υ.: {client.doy}</p>
        </div>
      </div>
      <div className={classes.printableVipContainer}>{client.vip === 1 &&(<p className={classes.printableVip}>{star} VIP Πελάτης</p>)}</div>
      <div className={classes.banner}><p>ΧΑΛΙΑ - ΜΟΚΕΤΕΣ - ΛΕΥΚΑ ΕΙΔΗ - ΕΙΔΗ ΣΚΙΑΣΗΣ - ΚΟΥΡΤΙΝΟΞΥΛΑ - ΤΑΠΕΤΣΑΡΙΕΣ ΤΟΙΧΟΥ & ΕΠΙΠΛΩΝ</p></div>
        <div id={classes.printableOrderFinancial}>
        <div className={classes.banner}><p>ΕΠΙΠΛΕΟΝ ΥΠΗΡΕΣΙΕΣ</p></div>
        <div className={classes.additionalServices}>
          {orderExtras.paradosi&&<p>
            Κατ'οίκον Παράδοση Χρέωση:{ orderExtras.paradosi +'€'}
          </p>}
          {orderExtras.metrisi&&<p>
            Κατ'οίκον Μέτρηση Χρέωση: {orderExtras.metrisi + '€'}
          </p>}
          {orderExtras.topothetisi>0 &&<p>
            Χρέωση Τοποθέτησης: { orderExtras.topothetisi+ '€'}
          </p>}
          {orderExtras.rafis &&<p>
            Χρέωση Ράφης: { orderExtras.rafis+ '€'}
          </p>}
          {orderExtras.sideroma &&<p>
            Χρέωση Σιδέρωμα Κουρτίνας: { orderExtras.sideroma+ '€'}
          </p>}
          {!orderExtras.sideroma &&<p>
            Σιδέρωμα: ΟΧΙ
          </p>}
        </div>
        
        <div className={classes.printPayment}>

          <div className={classes.printPaymentInfo}>
            <div className={classes.banner}><p>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</p></div>
            <div className={classes.printablePaymentBasic}>
              <p>
                Αρχική Τιμή: {orderPayment.initialPrice && orderPayment.initialPrice + '€'}
              </p>
              <p><strong>
                Σύνολο: {orderPayment.synolo && orderPayment.synolo+ '€'}
              </strong></p>
              {totalPayment>0 &&<p>
                Σύνολο Πληρωμένα: {totalPayment>0 && totalPayment+ '€'}
                
              </p>}
              <p style={{color:'red'}}>
                Υπόλοιπο: {balance && balance+ '€'}
              </p>
              {(orderExtras.topothetisi===0||orderExtras.topothetisi===undefined)&&<p style={{color:'red'}}>Χωρίς κατ' οικον τοποθέτηση</p>}
            </div>
          </div>
          
          <div className={classes.printablePaymentAnalysisInfo}>
          <div className={classes.banner}><p>ΑΝΑΛΥΣΗ ΠΛΗΡΩΜΩΝ:</p></div>
          {totalPayment>0&&
          <Fragment>
          <div className={classes.printPaymentAnalysis}>
          <p>
          {orderDetailed.slice(-6).map((result) =>{
                if (result.amount){
                  let formattedDate = '';
                  if (result.date.length>0){
                    const date= new Date(result.date);
                    formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
                  }
                  return(
                    <Fragment>
                    <p style={{color:'#555'}} class={classes.detailedPayments}>{formattedDate} - με {result.how==="metrita"?"μετρητά":result.how==="karta"?"κάρτα":"κατάθεση"}: {result.amount}€</p>
                    </Fragment>)
              }
              })}
          </p>
          </div>
          </Fragment>}
          </div>
          <div className={classes.einteriorInfo}>
          <div className={classes.banner}><p>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ:</p></div>
            <div className={classes.einteriorContact}>
              <p>ΦΕΙΔΩΝΟΣ 6</p>
              <p>ΑΡΓΟΣ</p>
              <p>27510 29561</p>
              <p>27510 63240</p> 
            </div>
          </div>
        </div>
      </div>
        <div className={classes.tracking}>
          Παρακολουθείτε την παραγγελία σας με κωδικό <strong>{order.tracking_number}</strong> στο <strong>morosdesignhouse.gr</strong>
        </div>
        <div style={{marginBottom:'20px'}} className={classes.bottomText}>
          <div className={classes.disclaimer}>
            Το υπόλοιπο εξοφλείται με την παράδοση/παραλαβή των προϊόντων. Σε περίπτωση τοποθέτησης κατ οίκον, η εξόφληση του υπολοίπου γίνεται πριν την τοποθέτηση. Τα εμπορεύματα αποτελούν ιδιοκτησία του καταστήματος μέχρι την εξόφληση τους και ταξιδεύουν για λογαριασμό, ευθύνη και έξοδα του αγοραστή. Η παράδοση των εμπορευμάτων στο μεταφορέα ισοδυναμεί με παράδοση στον αγοραστή. Αλλαγές σε μεταποιημένα προϊόντα ή κατασκευασμένα στα μέτρα σας, δε γίνονται σε καμία περίπτωση. Το παρόν έγγραφο δεν αποτελεί φορολογική απόδειξη.
          </div>
          <div className={classes.bankInfo}>
            <p>Να αναγράφεται ο αρ. παραγγελίας #{order.id} στην αιτιολογία</p>
            <p>Δικαιούχος: ΑΓΓΕΛΟΣ ΜΩΡΟΣ ΣΠΥΡΙΔΩΝ</p>
            <p>Πληρωμή με IRIS στο ΑΦΜ 135 592 599</p>
            <p>Eurobank - GR4502602610000260201342081</p>
            <p>Εθνική - GR7001103140000031401010280</p>
            <p>Viva Quickpay - https://pay.vivawallet.com/e-interior</p>
          </div>
        </div>
      

    </div>

    <div style={footerStyle} className={`${classes.printableFooter} ${classes.printable} `}>
        <div className={classes.banner}><p>ΛΙΣΤΑ ΠΡΟΪΟΝΤΩΝ</p></div>
        <div id={classes.printableOrder}>
          
          <div id={classes.printableOrderInfo}>
          {order.products &&
            order.products
              .filter((result) => result.status !== "deleted") // Filter out deleted products
              .map((result, index) => {
                return (
                  <div key={index} className={classes.productItemContainer}>
                    <span className={classes.productItem}>
                      <span>Προϊόν: <span>
                        {result.product_type}{" "}
                        {(result.product_type.includes("Ύφασμα") && !result.product_type.includes("Τραπεζομάνηλο") && !result.product_type.includes("Άλλη") && !result.product_type.includes("χωρίς")) && '- '+(parseFloat(JSON.parse(result.product_options).calculatedWidth) * parseFloat(JSON.parse(result.product_options).curtainHeight)).toFixed(2)+'m\u00B2'}
                        {(result.product_type.includes("Ύφασμα") && !result.product_type.includes("Τραπεζομάνηλο") && !result.product_type.includes("Άλλη")) && ' για κουρτινόξυλο: '+(parseFloat(JSON.parse(result.product_options).rodWidth)).toFixed(2)+'m'}
                        {result.product_type.includes("Τραπεζομάνηλο") && '- '+(parseFloat(JSON.parse(result.product_options).widthTrapezi) * parseFloat(JSON.parse(result.product_options).lengthTrapezi)).toFixed(2)+'m\u00B2'}
                        {(result.product_type.includes("Άλλη κατασκευή") || result.product_type.includes("Ύφασμα χωρίς ραφή")) && '- '+parseFloat(JSON.parse(result.product_options).fabricMeters).toFixed(2) +'m'}
                        {result.product_type.includes("Κουρτινόξυλο") && '- '+parseFloat(JSON.parse(result.product_options).rodWidth).toFixed(2) +'m πλάτος'}
                        {result.product_type.includes("Περσίδες") && '- '+(parseFloat(JSON.parse(result.product_options).rollerWidth) * parseFloat(JSON.parse(result.product_options).rollerHeight)).toFixed(2)+'m\u00B2'}
                        {result.product_type.includes("roller") && '- '+(parseFloat(JSON.parse(result.product_options).rollerWidth) * parseFloat(JSON.parse(result.product_options).rollerHeight)).toFixed(2)+'m\u00B2'}
                        {(result.product_type.includes("Μοκέτα") || result.product_type.includes("Πλαστικό") || result.product_type.includes("Γκαζόν")) && '- '+(parseFloat(JSON.parse(result.product_options).width) * parseFloat(JSON.parse(result.product_options).length)).toFixed(2)+'m\u00B2'}
                        {result.product_type.includes('Ρόμαν') && '- '+(parseFloat(JSON.parse(result.product_options).romanWidth) * parseFloat(JSON.parse(result.product_options).romanHeight)).toFixed(2)+'m\u00B2 '+JSON.parse(result.product_options).liningSelection}
                        {result.product_type.includes('Ψευτορόμαν') && '- '+(parseFloat(JSON.parse(result.product_options).rodWidth) * parseFloat(JSON.parse(result.product_options).romanWidth)).toFixed(2)+'m\u00B2'}
                        {result.product_type.includes('Διάδρομος') && '- '+(parseFloat(JSON.parse(result.product_options).width) * parseFloat(JSON.parse(result.product_options).length)).toFixed(2)+'m\u00B2'}
                        {result.product_type === "Έτοιμο χαλί" &&
                          JSON.parse(result.product_options).dimensions}
                        {result.product_type === "Σετ Κρεβατοκάμαρας" && <span>({JSON.parse(result.product_options).dimensionsOne}) ({JSON.parse(result.product_options).dimensionsTwo}) ({JSON.parse(result.product_options).dimensionsThree})</span>
                          }
                      </span></span>
                      
                      <span>Τιμή: <span>
                        {(JSON.parse(result.product_options).discountPrice||JSON.parse(result.product_options).totalPriceDiscount) ? (
                          <Fragment>
                            <small className={classes.lineThrough}>
                              <strike>{parseFloat(JSON.parse(result.product_options).totalPrice).toFixed(2)} €</strike>
                            </small>
                            <br />
                            <strong>{JSON.parse(result.product_options).totalPriceDiscount ? parseFloat(JSON.parse(result.product_options).totalPriceDiscount).toFixed(2): parseFloat(JSON.parse(result.product_options).discountPrice).toFixed(2)}€</strong>
                          </Fragment>
                        ) : (
                          <strong>{parseFloat(JSON.parse(result.product_options).totalPrice).toFixed(2)} €</strong>
                        )}
                      </span></span>
                      <span>Κωδικός: <span>
                        {result.code}{" "}
                        {JSON.parse(result.product_options).quantity > 1 && (
                          <Fragment>
                            <br />
                            {JSON.parse(result.product_options).totalPrice /
                              JSON.parse(result.product_options).quantity}
                            € x{JSON.parse(result.product_options).quantity} τμχ
                          </Fragment>
                        )}
                      </span></span>
                    </span>
                   {/* <span className={classes.productItem}>
                    </span>*/}
                    <span className={classes.productNotes}>
                      Σημειώσεις:{" "}
                      {JSON.parse(result.product_options).notes
                        ? JSON.parse(result.product_options).notes
                        : JSON.parse(result.product_options).freeText}
                    </span>
                  </div>
                );
              })}
        </div>
        
        </div>
    </div>
    {clientModal}
    {historyView}
    {viewModal}
    {editModal}
    {editTasksModal}
    {deliveryDateModal}
    {modalShow && (selection === "Roller") && <Roller  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Fabric") && <Fabrics  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Carpet") && <XaliMoketaPlastiko  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Blinds") && <Persides  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Roman") && <RomanPseudoroman  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Curtain") && <CurtainRod  OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Custom") && <CustomProduct OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Diadromos") && <Diadromos OrderId={props.id} onClose={addCloseHandler} onSave={addSaveHandler}/>}
    <button className={`${classes.close} ${classes.notPrintable}`} onClick={handleBack}>Πίσω</button>
    {order.status==="proxeiro"&&<div className={classes.submitOrder}>
    <Button className={`${classes.submitOrderButton} ${classes.notPrintable}`} onClick={handleSubmitOrder}>Καταχώρηση</Button>
    </div>}
    {order.status!=="proxeiro"&&<div className={classes.submitOrder}>
    <Button className={`${classes.submitOrderButton} ${classes.notPrintable}`} onClick={handleOfferOrder}>Μετρατροπή σε Προσφορά</Button>
    </div>}
   
    
    <div className={classes.notPrintable}>
  
      <h2>{order.status!=="proxeiro"?"Παραγγελία":"Προσφορά"} <span className={classes.printIcon} onClick={handlePrintClick}>{print}</span></h2>
      {order.status!=="proxeiro" && <p className={classes.deliveryDate}>
      Ημ. Παράδοσης {showDeliverDate&&new Date(showDeliverDate).toLocaleDateString("el-GR")}
      <button onClick={handleDeliveryDateClick}>
        {pencil}
      </button>
      </p>}
      {deletePaymentModal}
      {notifyModal}
      {completeModal}
      <div className={classes.grid}>
      <div className={classes.left}>
        <p>{client.warning === 1 &&(<span style={{color:'red'}}>{warning}</span>)}Πελάτης: {props.fullName} <span onClick={handleClientView}>{view}</span></p>
        <p>{client.vip === 1 &&(<span>{star} VIP Πελάτης</span>)}</p>
        <p>Τηλ. :{client.phone && client.phone} {communicateAvailable}</p>
        {orderExtras.paradosi > 0 && <p>Παράδοση: {orderExtras.paradosi}€</p>}
        {orderExtras.metrisi > 0 && <p>Μέτρηση: {orderExtras.metrisi}€</p>}
        {orderExtras.topothetisi > 0 && <p>Τοποθέτηση: {orderExtras.topothetisi}€</p>}
        {orderExtras.rafis > 0 && <p>Ραφή: {orderExtras.rafis}€</p>}
        {orderExtras.sideroma > 0 && <p>Σιδέρωμα: {orderExtras.sideroma}€</p>}
        {orderPayment.initialPrice>0 && <p>Αρχική Τιμή: {orderPayment.initialPrice}€</p>}
        {!totalPriceEdit && <p>Σύνολο: {orderPayment.synolo}€ <span onClick={totalEditClick}>{pencil}</span></p>}
        {totalPriceEdit && <p>
        <Input onChange={totalPriceValChangeHandler}
          vert="true"
          label="Σύνολο:"
          isPrice="true"
          input={{
            id: 9,
            type:'text',
            value: totalPriceVal
          }} /> <span onClick={totalSaveClick}>{save}</span></p>}
        <Button className={classes.recalc} onClick={recalculateTotals}>Επανυπολογισμός</Button>
        <Button className={classes.recalc} onClick={handleEditTasksClick}>Επεξεργασία Χρεώσεων</Button>
        {orderDetailed && 
        <Fragment>
          {orderDetailed.map((result) =>{
            if (result.amount){
              let formattedDate = '';
              if (result.date.length>0){
                const date= new Date(result.date);
                formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
              }
              return(
                <Fragment>
                <p className={classes.paymentListItem}><span onClick={()=>handleDeletePaymentClick(formattedDate,result.type,result.how,result.amount)}>{xsign}</span> {formattedDate} - {result.type==="prokatavoli"?"Προκαταβολή":"Πληρωμή"} με {result.how==="metrita"?"μετρητά":result.how==="karta"?"κάρτα":"κατάθεση"}: {result.amount}€ </p>
                </Fragment>)
          }
          })}
        </Fragment>}
        {!makingPayment &&<Button className={classes.newPayment} onClick={newPaymentHandler}>Νέα Πληρωμή</Button>}
        {makingPayment &&
        <Fragment>
        <p>
         <select id="paymentSelection" name="paymentSelection" onChange={paymentSelectionHandler}>
          <option value="metrita">Μετρητά</option>
          <option value="karta">Κάρτα</option>
          <option value="katathesi">Κατάθεση</option>
        </select> </p>
        <p><Input onChange={paymentInputChangeHandler}
          vert="true"
          label="Νέα Πληρωμή:"
          isPrice="true"
          input={{
            id: 9,
            type:'text',
            value: paymentInput
          }} /></p>
          <div>
            <Button className={classes.cancelPayment} onClick={cancelPaymentHandler}>Ακύρωση</Button>
            <Button className={classes.newPayment} onClick={savePaymentHandler}>Αποθήκευση</Button>
          </div>

        </Fragment>
        }
        <p className={`${classes.ypoloipo} ${balance<0&&classes.arnhtikoYpoloipo}`}>Υπόλοιπο: {balance}€</p>
        <div className={classes.textarea}>
        <label htmlFor="sku"><p>Σημείωση Παραγγελίας: </p></label>
        <textarea id="sku" rows="2" value={notes} ref={textareaRef} onChange={notesChangeHandler} style={{ overflow: 'hidden' }} ></textarea>
        </div>
        <div style={{marginTop:'10px'}}>
          <Button className={classes.newPayment} onClick={saveNotesHandler}>Αποθήκευση</Button>
        </div>
      </div>
      <div className={classes.right}>
        <table className={classes.viewTable}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Κωδικός/Περιγραφή</th>
              <th>Είδος</th>
              <th>Εταιρεία</th>
              <th>Τιμή</th>
              <th>Έξοδα</th>
              <th>Σημειώσεις</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
          {order.products && order.products.map((result) =>{
          const updatedDate = new Date(result.updated_at);
          const currentDate = new Date();
          const timeDiff = currentDate.getTime() - updatedDate.getTime();
          const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
 
          const statusUpdateDate = new Date(result.status_updated_at).toLocaleString().split(',')[0];
          const statusUpdateTime = new Date(result.status_updated_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: true});
  
          const formattedStatusDate = `${statusUpdateDate} ${statusUpdateTime}`
          return(
            <tr className={`${classes.productList} ${result.status==='deleted' && classes.deleted}`}>
              <td>
              {result.status!=="deleted" &&<div className={classes.selectGrid}>
                <div>
                {(JSON.parse(result.product_options).mechanismOrdered==1 && JSON.parse(result.product_options).meterPriceMechanism !="")?<p>Παραγγέλθηκε μηχανισμός</p>:""}
                <select className={`${classes.statusSelect} ${(dayDiff>=3&&dayDiff<6&&result.status!=="submitted")&&classes.threeDays} ${(dayDiff>=6&&result.status!=="submitted")&&classes.sixDays}`} value={result.status} onChange={(event)=>handleProductStatusChange(result.id,props,event)}>
                    <option value="submitted">Για Παραγγελία</option>
                    <option value="ordered">Παραγγέλθηκε</option>
                    <option value="rafeio">Ραφείο</option>
                    {(result.product_type==="Ρόμαν"||result.product_type==="Ψευτορόμαν")&&<option value="merikos">Μερικώς έτοιμο</option>}
                    <option value="ready">Έτοιμο</option>
                    <option value="sent">Το πήρε</option>
                    <option value="repair">Επιδιόρθωση</option>
                    <option value="modify">Ρέλι/Μεταποίηση</option>
                </select>
                  {result.status=="rafeio"&&
                  <div className={classes.rafeioDiv} >
                    <select className={classes.tailorSelect}  value={rafeioNote?.find(obj => obj.id === result.id)?.last_status[0]?.note ?? '0'}  onChange={(event)=>rafeioNoteChangeHandler(event,result.id)}>
                    <option value="0"></option>
                    <option value="ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ">ΠΑΝΑΓΙΩΤΑ ΑΡΓΟΣ</option>
                    <option value="ΑΡΗΣ ΣΙΟΥΤΗΣ">ΑΡΗΣ ΣΙΟΥΤΗΣ</option>
                    <option value="ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ">ΗΛΙΑΣ ΑΛΕΞΟΠΟΥΛΟΣ</option>
                    <option value="ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ">ΑΛΕΞΗΣ ΑΛΕΞΟΠΟΥΛΟΣ</option>
                    <option value="ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ">ΚΑΡΑΧΑΛΙΟΥ ΑΡΓΟΣ</option>
                    <option value="ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ">ΜΑΡΙΑΝΝΑ-ΤΡΙΠΟΛΗ</option>
                    <option value="ΑΛΛΟ">ΆΛΛΟ</option>
                    </select>
                    
                    <p className={classes.rafeioSaveBtn} onClick={(event)=>handleRafeioSave(rafeioNote?.find(obj => obj.id === result.id)?.last_status[0]?.note ?? '', result.last_status[0].id, unlistedTailor?.find(obj => obj.id === result.id)?.status)}>{save}</p>
                    {rafeioNote?.find(obj => obj.id === result.id)?.last_status[0]?.note==="ΑΛΛΟ"&&
                    <Input
                    className={classes.rafeioInput}
                    onChange={(event)=>unlistedTailorChangeHandler(event,result.id)}
                    label="Ραφείο:"
                    input={{
                      id: 1,
                      type: 'text',
                      value: unlistedTailor?.find(obj => obj.id === result.id)?.status
                    }} /> 
                  }
                  </div>
                  }
                  {rafeioNote.length>0&&
                    <Fragment>
                    {(result.status!=="rafeio"&&rafeioNote?.find(obj => obj.id === result.id)?.last_status.find(obj => obj.status === 'rafeio'))&&
                      <div className={classes.rafeioDiv}><p className={classes.rafeioText}>Ραφείο: {rafeioNote?.find(obj => obj.id === result.id)?.last_status.find(obj => obj.status === 'rafeio')?.note ?? ''}</p></div>
                    }
                  </Fragment>}
                  {result.status=="merikos"&&
                  <div className={classes.merikosDiv}>
                    <div>
                      <label>Ύφασμα</label>
                      <input type="checkbox" checked={result.last_status[0].fabric_ready=="1"} onChange={(event)=>handleFabricCheckboxChange(event,result.last_status[0].id)}/>
                    </div>
                    <div>
                      <label>Μηχανισμός</label>
                      <input type="checkbox" checked={result.last_status[0].mechanic_ready=="1"} onChange={(event)=>handleMechanismCheckboxChange(event,result.last_status[0].id)}/>
                    </div>
                  </div>
                  }
                </div>
                <span className={classes.updateStatusDate}>{result.status_updated_at && formattedStatusDate}
                  <span title="Ιστορικό" onClick={()=>historyClick(result.id)}>{history}</span>
                </span>
                </div>}
              </td>
              <td>{result.code} {JSON.parse(result.product_options).quantity&&"x"+JSON.parse(result.product_options).quantity+" τμχ"}</td>
              <td>{result.product_type}</td>
              <td>{result.manufacturer}</td>
              {!(JSON.parse(result.product_options).quantity>0)&&
              <td>{JSON.parse(result.product_options).discountPrice ?
               JSON.parse(result.product_options).discountPrice:JSON.parse(result.product_options).totalPrice} €
               </td>}
              {JSON.parse(result.product_options).quantity>0&&
              <td style={{whiteSpace:'nowrap'}} className={classes.priceQuantity}><p className={`${JSON.parse(result.product_options).quantity>1 && classes.singleItemPrice} ${(JSON.parse(result.product_options).discountPrice) && classes.strikeThrough}`}>{Math.round(JSON.parse(result.product_options).totalPrice/JSON.parse(result.product_options).quantity*100)/100} € {JSON.parse(result.product_options).quantity>1 &&" x "+JSON.parse(result.product_options).quantity}</p> 
               {JSON.parse(result.product_options).quantity>1 && <p className={JSON.parse(result.product_options).discountPrice && classes.strikeThrough}>
                {parseFloat(JSON.parse(result.product_options).totalPrice).toFixed(2)} €</p>}
               {(JSON.parse(result.product_options).discountPrice||JSON.parse(result.product_options).totalPriceDiscount) && 
               <p>{JSON.parse(result.product_options).totalPriceDiscount?parseFloat(JSON.parse(result.product_options).totalPriceDiscount).toFixed(2):parseFloat(JSON.parse(result.product_options).discountPrice).toFixed(2)} €</p>}
               </td>}
              <td className={classes.wholesalePrice}>
                <div>
                  <p><Input onChange={(event)=>wholesalePriceChangeHandler(event,result.id)}
                  vert="true"
                  mobileVert="true"
                  label="T.Χ με ΦΠΑ:"
                  isPrice="true"
                  input={{
                    type:'text',
                    value: JSON.parse(result.expenses).wholesale
                  }} /></p>
                  <p><Input onChange={(event)=>shippingPriceChangeHandler(event,result.id)}
                  vert="true"
                  mobileVert="true"
                  label="Μεταφορικά:"
                  isPrice="true"
                  input={{
                    type:'text',
                    value: JSON.parse(result.expenses).shipping
                  }} /></p>
                  <p><Input onChange={(event)=>assistantPriceChangeHandler(event,result.id)}
                  vert="true"
                  mobileVert="true"
                  label="Βοηθός:"
                  isPrice="true"
                  input={{
                    type:'text',
                    value: JSON.parse(result.expenses).assistant
                  }} /></p>
                  {(result.product_type.includes("Ύφασμα")||result.product_type.includes("Άλλο")||result.product_type.includes("Ρόμαν")||result.product_type.includes("Ψευτορόμαν"))&&<p><Input onChange={(event)=>sewingPriceChangeHandler(event,result.id)}
                  vert="true"
                  mobileVert="true"
                  label="Ραφείο:"
                  isPrice="true"
                  input={{
                    type:'text',
                    value: JSON.parse(result.expenses).sewing
                  }} /></p>}
                </div>
                <p className={classes.wholesaleSaveContainer}>{result.wholesaleChange &&<Button className={classes.wholesaleSave} onClick={()=>handleWholesaleSave(result.id,result.expenses, result.product_options)}>{save}</Button>}</p>
              </td>
              <td className={classes.viewNotes}>{JSON.parse(result.product_options).notes && JSON.parse(result.product_options).notes.length>50?JSON.parse(result.product_options).notes.substring(0,50)+'...':JSON.parse(result.product_options).notes}{JSON.parse(result.product_options).freeText&&JSON.parse(result.product_options).freeText.length>50?JSON.parse(result.product_options).freeText.substring(0,50)+'...':JSON.parse(result.product_options).freeText}</td>
              <td><p><span style={{marginRight:1 + 'em'}} onClick={()=>viewClickHandler(result.id,result.product_type)}>{view}</span>
                  <span onClick={()=>editClickHandler(result.id,result.product_type)}>{pencil}</span></p>
                  <p><span style={{marginRight:1 + 'em'}} onClick={()=>copyClickHandler(result.id,result.product_type)}>{copy}</span>
                  <span onClick={()=>deleteClickHandler(result.id,result.status)}>{trash}</span></p>
              </td>
            </tr>
            )
          })}
          </tbody>
        </table>
          <div className={classes.searchBlock}>
            <span>
              <label htmlFor="addNew">Προσθήκη νέου προϊόντος: </label>
              <select id="addNew" name="Προσθήκη" onChange={selectChangeHandler}>
                <option value="0"> </option>
                <option value="Curtain">Κουρτινόξυλο</option>
                <option value="Blinds">Περσίδες</option>
                <option value="Roman">Ρόμαν-Ψευτορόμαν</option>
                <option value="Roller">Roller</option>
                <option value="Fabric">Ύφασμα</option>
                <option value="Carpet">Χαλί-Μοκέτα-Πλαστικό</option>
                <option value="Diadromos">Διάδρομος</option>
                <option value="Custom">Άλλο είδος</option>
              </select>
            </span>
            <span className={classes.add} onClick={addClickHandler}><strong>{plus}</strong></span>
          </div>
          <div>
            <p className={classes.profitText}>Κέρδος: {totalProfit}€</p>
          </div>
          {showDeliverDate&&<div>
            <p className={deliverClass}>{deliverMessage}</p>
          </div>}
      </div>
      </div>
    </div>
    
    </Fragment>
    )
}
export default ViewOrderInProgress;
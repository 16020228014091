import React, {useState, useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import classes from './AtTailor.module.css'
import SingleAtTailor from './SingleAtTailor'
import SingleAtTailorHistory from './SingleAtTailorHistory'
import ViewOrderInProgress from '../OrdersInProgress/ViewOrderInProgress'
import ViewOrderHistory from '../OrderHistory/ViewOrderHistory'
import { Navigate, Route, Routes, useNavigate,Outlet, Link, useLocation  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const AtTailor = () =>{

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [scrollPos, setScrollPos] = useState(0);
  const [products,setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [companies, setCompanies] = useState('');
  const [currentClients, setCurrentClients] = useState('');
  const [currentId,setCurrentId] = useState('');
  const [currentFullName,setCurrentFullName] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [statusChange, setStatusChange] = useState(false);
  const [clientSelection, setClientSelection] = useState('0')
  const [currentTailors, setCurrentTailors] = useState('');
  const [tailorSelection, setTailorSelection] = useState('0');
  const [showOrdered, setShowOrdered] = useState(false);
  const [modalShow,setModalShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [historyProducts, setHistoryProducts] = useState([]);
  const location = useLocation();
  const [historyClients, setHistoryClients] = useState(''); 
  const [historyTailors, setHistoryTailors] = useState('');
  const [historyModalShow, setHistoryModalShow] = useState(''); 

  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
    setIsLoaded(true);
      if(location.state){
      setCurrentId(location.state.currentId);
      setCurrentFullName(location.state.currentFullName);
      setModalShow(true);
    }

}, []);


      

  useEffect(() => {
    if (isLoaded){
      const config = {
          headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
        };
        axios.post('https://api.orders.e-interior.gr/api/general/init',{
          key: cookies.Token},
          config
        )
        .then(function (response) {
          setCompanies(response.data[0].companies.sort());
    
        })
        .catch(function (error) {
          console.log(error);
        });
    
        axios.post('https://api.orders.e-interior.gr/api/rafeioProducts',{
          key: cookies.Token},
          config
        )
        .then(function (response) {
          console.log(response.data)
          setProducts(response.data.sort(function(a,b) {
            if (a.order_id < b.order_id){
              return -1;
            }else if(a.order_id > b.order_id){
              return 1;
            }else{
              return 0;
            }
          }))
        })
        .catch(function (error) {
          console.log(error);
        });}
  },[isLoaded, productUpdate, showOrdered])

  useEffect(()=>{
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };
    axios.post('https://api.orders.e-interior.gr/api/rafeioHistory',{
          key: cookies.Token},
          config
        )
        .then(function (response) {
          console.log(response.data)
          setHistoryProducts(response.data.sort(function(a,b) {
            if (a.order_id < b.order_id){
              return 1;
            }else if(a.order_id > b.order_id){
              return -1;
            }else{
              return 0;
            }
          }))
        })
        .catch(function (error) {
          console.log(error);
        });
  },[isLoaded])

  useEffect(()=>{

    setCurrentClients([...new Set(products.map(item => item.clientName))]);
    setCurrentTailors([
  ...new Set(
    products
      .map(item => {
        // Check if last_status exists and is an array
        const tailor = Array.isArray(item.last_status) 
          ? item.last_status.find(statusItem => statusItem.status === "rafeio") 
          : null;
        
        // Return the note if tailor exists, otherwise return null
        return tailor ? tailor.note : null;
      })
      // Filter out any null values to avoid adding them to the Set
      .filter(note => note !== null)
  )
]);
  },[products])

  useEffect(()=>{
    console.log(historyProducts);
    setHistoryClients([...new Set(historyProducts.map(item => item.clientName))]);
    setHistoryTailors([
  ...new Set(
    historyProducts
      .map(item => {
        // Check if last_status exists and is an array
        const tailor = item.tailor;

        
        // Return the note if tailor exists, otherwise return null
        return tailor ? tailor : null;
      })
      // Filter out any null values to avoid adding them to the Set
      .filter(note => note !== null)
  )
]);
  },[historyProducts])

  const clientSelectionHandler = (event) =>{
    setClientSelection(event.target.value);
  }
  const tailorSelectionHandler = (event) =>{
    setTailorSelection(event.target.value);
  }
  const handleOrdered = ()=>{
    setProductUpdate(!productUpdate);
  }

const handleModalShow = (e) =>{
    setModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentFullName(e.fullName);
    setCurrentStatus(e.status);
    setScrollPos(e.scrollPos);
  }

const handleHistoryModalShow = (e) =>{
    setHistoryModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentFullName(e.fullName);
    setCurrentStatus(e.status);
    setScrollPos(e.scrollPos);
  }


const onCloseHandler = () =>{
    setModalShow(false);
    setHistoryModalShow(false);
    setCurrentId('');
    setCurrentFullName('');
    setCurrentStatus('');
    setStatusChange(!statusChange);
  }

const handleShowHistoryChange = () =>{
  setShowHistory(!showHistory);
  setClientSelection('0');
  setTailorSelection('0');
}
  return(
    <Fragment>
    <Card className={`${classes.ordersCard} ${classes.notPrintable}`}>
    {!showHistory &&<h2 className={classes.notPrintable}>Στο Ραφείο</h2>}
    {showHistory &&<h2 className={classes.notPrintable}>Ιστορικό Ραφείου</h2>}
    {!modalShow && !historyModalShow && <Fragment>
    
      <div className={classes.switchContainer}>
        <label className={classes.switch}>
          <input type="checkbox" checked={showHistory} onChange={handleShowHistoryChange}/>
          <span className={classes.slider} />
        </label>
        <p>Προβολή Ιστορικού</p>
      </div>
    {!showHistory&&<table>
      <thead>
        <tr>
          <th>#</th>
          <th><select id="client" name="client" onChange={clientSelectionHandler} value={clientSelection}>
            <option value="0"> --- Πελάτης --- </option>
            {currentClients && currentClients.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Προϊόν</th>
          <th>Τιμή Ραφής</th>
          <th>Μεταφορικά</th>
          <th><select id="tailor" name="tailor" onChange={tailorSelectionHandler} value={tailorSelection}>
            <option value="0"> --- Ον. Ραφείου --- </option>
            {currentTailors && currentTailors.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Ημ. παράδοσης στο ραφείο</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {products.map(({id,order_id,expenses,clientName, vip, code,product_type,last_status,status_updated_at, product_options})=>{
        const findTailor = last_status.find(item => item.status === "rafeio");
        const tailorNote = findTailor ? findTailor.note : null;
        if((clientSelection==="0"&&tailorSelection==="0")||
          (clientSelection===clientName&&tailorSelection==="0")||
          (clientSelection==="0"&&tailorSelection===tailorNote)||
          (clientSelection===clientName&&tailorSelection===last_status[0].note)){
            return(
              <SingleAtTailor
              modalShow={handleModalShow}
              id={id}
              order_id={order_id}
              quantity={JSON.parse(product_options).quantity}
              expenses = {expenses}
              sewingPrice = {JSON.parse(expenses).sewing}
              key={id}
              clientName={clientName}
              vip={vip}
              status_updated_at={last_status[0].updated_at.replace("T", " ").split('.')[0]}
              tailorName={tailorNote}
              statusId = {findTailor?findTailor.id:null}
              code={code} 
              product_type={product_type}
              onOrdered={handleOrdered}/>
              )}
        }
      )}
      </tbody> 
    </table>}
    {showHistory&&<table>
      <thead>
        <tr>
          <th>#</th>
          <th><select id="client" name="client" onChange={clientSelectionHandler} value={clientSelection}>
            <option value="0"> --- Πελάτης --- </option>
            {historyClients && historyClients.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Προϊόν</th>
          <th>Τιμή Ραφής</th>
          <th>Μεταφορικά</th>
          <th><select id="tailor" name="tailor" onChange={tailorSelectionHandler} value={tailorSelection}>
            <option value="0"> --- Ον. Ραφείου --- </option>
            {historyTailors && historyTailors.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
          </select></th>
          <th>Ημ. παράδοσης στο ραφείο</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {historyProducts.map(({id,order_id,expenses,clientName, vip, code,product_type,note,status_updated_at, product_options,rafeio_created_at,rafeio_status_id,tailor})=>{
        const tailorNote = tailor ? tailor : null;
        if((clientSelection==="0"&&tailorSelection==="0")||
          (clientSelection===clientName&&tailorSelection==="0")||
          (clientSelection==="0"&&tailorSelection===tailorNote)||
          (clientSelection===clientName&&tailorSelection===note)){
            return(
              <SingleAtTailorHistory
              modalShow={handleHistoryModalShow}
              id={id}
              order_id={order_id}
              quantity={JSON.parse(product_options).quantity}
              expenses = {expenses}
              sewingPrice = {JSON.parse(expenses).sewing}
              key={id}
              clientName={clientName}
              vip={vip}
              status_updated_at={rafeio_created_at.replace("T", " ").split('.')[0]}
              tailorName={tailorNote}
              statusId = {rafeio_status_id?rafeio_status_id:null}
              code={code} 
              product_type={product_type}
              onOrdered={handleOrdered}/>
              )}
        }
      )}
      </tbody> 
    </table>}
    </Fragment>}
    </Card>
    {modalShow && <ViewOrderInProgress id={currentId} fullName={currentFullName} onClose={onCloseHandler} />}
    {historyModalShow && <ViewOrderHistory id={currentId} fullName={currentFullName} onClose={onCloseHandler} />}
    </Fragment>
  )
}
export default AtTailor;

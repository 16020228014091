import React,{useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import { Navigate, Route, Routes, useNavigate,Outlet, Link, useLocation  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import SingleOrderInProgress from './SingleOrderInProgress'
import ViewOrderInProgress from './ViewOrderInProgress'
import NotifyClient from './NotifyClient'
import CompleteOrder from './CompleteOrder'
import SubmitOrder from './SubmitOrder'
import CancelOrder from './CancelOrder'
import classes from './OrdersInProgress.module.css';
import axios from 'axios';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'; 


const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />

const OrdersInProgress = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [scrollPos, setScrollPos] = useState(0);
  const [showProfit, setShowProfit] = useState(false);
  const [showReady, setShowReady] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const [orders,setOrders] = useState([]);
  const [clients,setClients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editClick,setEditClick] = useState(true);
  const [modalShow,setModalShow] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [currentId,setCurrentId] = useState('');
  const [currentFullName,setCurrentFullName] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [saved, setSaved] = useState('');
  const [statusChange, setStatusChange] = useState(false);
  const [balance, setBalance] = useState(0);
  const [orderRender, setOrderRender] = useState('')
  const [currentClients, setCurrentClients] = useState('');
  const [nameSelection, setNameSelection] = useState('0')
  const location = useLocation();

  const [singleSelections, setSingleSelections] = useState([]);
  const [options,setOptions]=useState([{id:'',label:''}])



    useEffect(() => {
      setIsLoaded(true);
      if(location.state){
      setCurrentId(location.state.currentId);
      setCurrentFullName(location.state.currentFullName);
      setModalShow(true);
    }

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

  }, []);

    useEffect(()=>{
      document.querySelector("html").scrollTo(0, scrollPos);
    }, [scrollPos,orderRender])

    useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/orders',{
      key: cookies.Token,
      filter: 'in_progress'
    },
      config
    )
    .then(function (response) {
      setOrders(response.data);
      console.log(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });

    //get all clients
    // axios.post('https://api.orders.e-interior.gr/api/clients',{
    //   key: cookies.Token},
    //   config
    // )
    // .then(function (response) {
    //   setClients(response.data);

    // })
    // .catch(function (error) {
    //   if (error.response.data.message==="Unauthenticated."){
    //         removeCookies('Name', { path: '/' });
    //         removeCookies('Token', { path: '/' });
    //         navigate("/user-login");
    //       }
    //   console.log(error);
    // });

  },[ modalShow, statusChange])

  function handleClearClick(){
    setSingleSelections([]);
  }

  useEffect(()=>{
    setCurrentClients([...new Set(orders.map(item => item.fullName)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    )]);
  },[orders])

  useEffect(()=>{
    let code = ''
    let notes = '';
    let labelStr = '';
    let opt = [];

    for (let order of orders){
      for (let product of order.products){
        if (product.status!=='deleted'){
        code = product.code
        notes = JSON.parse(product.product_options).notes ? JSON.parse(product.product_options).notes:JSON.parse(product.product_options).freeText?JSON.parse(product.product_options).freeText:'';
        labelStr =code+(notes?' '+notes:'');
        opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: labelStr,
          })
      }
      }
    }
    for (let client of currentClients){
      opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: client,
          })
    }
    opt = opt.reduce((acc, cur) => {
      if (!acc.includes(cur.label)) {
        acc.push(cur.label);
      }
      return acc;
    }, [])
    setOptions(opt)
}, [currentClients])


  const handleModalShow = (e) =>{
    setModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentFullName(e.fullName);
    setCurrentStatus(e.status);
    setScrollPos(e.scrollPos);
  }

  const onCloseHandler = () =>{
    setModalShow(false);
    setCurrentId('');
    setCurrentFullName('');
    setCurrentStatus('');
    setStatusChange(!statusChange);
  }


  const nameSelectionHandler = (e) =>{
    setNameSelection(e.target.value);
  } 

  const handleCommunicateClick = (e) =>{
    setNotifyModal(true);
    setCurrentId(e.id);
  }

  const handleCommunicateYes = (e) =>{
    
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: e.id,
      status: "communicated"
      },
      config)
    .then(function (response) {
      setNotifyModal(false);
      setStatusChange(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });
  }
  const handleCommunicateNo = (e) =>{
    setNotifyModal(false);
  }
  const handleCheckClick = (e) =>{
    setCompleteModal(true);
    setCurrentId(e.id);
    
  }
  const handleCheckYes = (e) =>{
    
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: e.id,
      status: "completed"
      },
      config)
    .then(function (response) {
      setCompleteModal(false);
      setStatusChange(response.data)

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });
  }
  const handleCheckNo = (e) =>{
    setCompleteModal(false);
  }

  const handleSubmitClick = (e)=>{
    setSubmitModal(true)
    setCurrentId(e.id);
  }
  const handleSubmitYes = (e) =>{
    
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: e.id,
      status: "submitted"
      },
      config)
    .then(function (response) {
      setSubmitModal(false);
      setStatusChange(response.data)

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });
  }
  const handleSubmitNo = (e) =>{
    setSubmitModal(false);
  }
  const handleCancelClick = (e) =>{
    setCancelModal(true);
    setCurrentId(e.id);
  }
  const handleCancelYes = (e) =>{
    
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: e.id,
      status: "canceled"
      },
      config)
    .then(function (response) {
      setCancelModal(false);
      setStatusChange(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });
  }
  const handleCancelNo = (e) =>{
    setCancelModal(false);
  }

  


  // useEffect(()=>{
  //   if (showReady){
  //     let tempBalance = 0;
  //     let paidSum = 0;
  //     const orderComponents = orders.map(order => {

  //         paidSum = 0;
  //           if (!order.client){
  //             return;
  //           }
  //           if (singleSelections.length===0){
  //             let payments = [];
  //             if (order && order.payment) {

  //               let parsedPayment = JSON.parse(order.payment);

  //               if (parsedPayment && parsedPayment.detailed) {
  //                   payments = JSON.parse(parsedPayment.detailed);
  //               }
  //             }
  //             // let payments = JSON.parse(JSON.parse(order.payment).detailed);
  //             for (let payment of payments){
  //               if (payment.amount>0){
  //                paidSum += Number(payment.amount);
  //               }
  //             }
  //             tempBalance += Math.round((Number(order.totalPrice) - Number(paidSum))*100)/100;
  //           return(
  //             <SingleOrderInProgress
  //               modalShow={handleModalShow}
  //               communicateClick={handleCommunicateClick}
  //               checkClick={handleCheckClick}
  //               cancelClick={handleCancelClick}
  //               submitClick={handleSubmitClick}
  //               statusChange={statusChange}
  //               id={order.id}
  //               key={order.id}
  //               fullName={order.fullName}
  //               phone={order.client.phone}
  //               vip={order.client.vip}
  //               warning={order.client.warning}
  //               notes={JSON.parse(order.extras).notes}
  //               totalProducts={order.totalProducts}
  //               totalPrice={order.totalPrice}
  //               payment={JSON.parse(order.payment)}
  //               status={order.status}
  //               created_at={order.created_at}
  //               hasInstallation={order.hasInstallation}
  //               status_updated_at={order.status_updated_at}
  //               showProfit={showProfit}
  //               showOffers={showOffers}
  //               products={order.products}
  //             />
  //           )}else{
  //             let codeList = [];
  //             let noteList = []
  //             for (let product of order.products){
  //               codeList.push(product.code);
  //               if (JSON.parse(product.product_options).notes){
  //                 noteList.push(JSON.parse(product.product_options).notes);
  //               }else if(JSON.parse(product.product_options).freeText){
  //                 noteList.push(JSON.parse(product.product_options).freeText)
  //               }else{
  //                 noteList.push('');
  //               }
  //             }
  //             const combinedList = codeList.map((element, index) => element + noteList[index]);

              
  //             if (singleSelections){

  //               if((combinedList.map(item => item.trim()).includes(singleSelections[0].trim())) ||
  //                 singleSelections[0] === order.fullName){
  //                   let payments = [];
  //                   if (order && order.payment) {

  //                     let parsedPayment = JSON.parse(order.payment);

  //                     if (parsedPayment && parsedPayment.detailed) {
  //                         payments = JSON.parse(parsedPayment.detailed);
  //                     }
  //                   }
  //                   // let payments = JSON.parse(JSON.parse(order.payment).detailed);
  //                   for (let payment of payments){
  //                     if (payment.amount>0){
  //                      paidSum += Number(payment.amount);
  //                     }
  //                   }
  //                   tempBalance += Math.round((Number(order.totalPrice) - Number(paidSum))*100)/100;
  //                 return(
  //                   <SingleOrderInProgress
  //                     modalShow={handleModalShow}
  //                     communicateClick={handleCommunicateClick}
  //                     checkClick={handleCheckClick}
  //                     cancelClick={handleCancelClick}
  //                     submitClick={handleSubmitClick}
  //                     statusChange={statusChange}
  //                     id={order.id}
  //                     key={order.id}
  //                     fullName={order.fullName}
  //                     phone={order.client.phone}
  //                     vip={order.client.vip}
  //                     warning={order.client.warning}
  //                     notes={JSON.parse(order.extras).notes}
  //                     totalProducts={order.totalProducts}
  //                     totalPrice={order.totalPrice}
  //                     payment={JSON.parse(order.payment)}
  //                     status={order.status}
  //                     created_at={order.created_at}
  //                     hasInstallation={order.hasInstallation}
  //                     status_updated_at={order.status_updated_at}
  //                     showProfit={showProfit}
  //                     showOffers={showOffers}
  //                     products={order.products}
  //                   />
  //                 )
  //               }
  //             }
  //           }
  //       });
  //       setBalance(tempBalance);
  //       setOrderRender(orderComponents);
  //   }else if(showOffers){
  //     const orderComponents = orders.filter(order =>
  //       order.status == "proxeiro"
  //       ).map(order => {

  //           if (!order.client){
  //             return;
  //           }
  //           if (singleSelections.length===0){
  //           return(
  //             <SingleOrderInProgress
  //               modalShow={handleModalShow}
  //               communicateClick={handleCommunicateClick}
  //               checkClick={handleCheckClick}
  //               cancelClick={handleCancelClick}
  //               submitClick={handleSubmitClick}
  //               statusChange={statusChange}
  //               id={order.id}
  //               key={order.id}
  //               fullName={order.fullName}
  //               phone={order.client.phone}
  //               vip={order.client.vip}
  //               warning={order.client.warning}
  //               notes={JSON.parse(order.extras).notes}
  //               totalProducts={order.totalProducts}
  //               totalPrice={order.totalPrice}
  //               payment={JSON.parse(order.payment)}
  //               status={order.status}
  //               created_at={order.created_at}
  //               hasInstallation={order.hasInstallation}
  //               status_updated_at={order.status_updated_at}
  //               showProfit={showProfit}
  //               showOffers={showOffers}
  //               products={order.products}
  //             />
  //           )}else{
  //             let codeList = [];
  //             let noteList = []
  //             for (let product of order.products){
  //               codeList.push(product.code);
  //               if (JSON.parse(product.product_options).notes){
  //                 noteList.push(JSON.parse(product.product_options).notes);
  //               }else if(JSON.parse(product.product_options).freeText){
  //                 noteList.push(JSON.parse(product.product_options).freeText)
  //               }else{
  //                 noteList.push('');
  //               }
  //             }
  //             const combinedList = codeList.map((element, index) => element + ' '+noteList[index]);
              
  //             if (singleSelections){
  //               if((combinedList.map(item => item.trim()).includes(singleSelections[0].trim())) ||
  //                 singleSelections[0] === order.fullName){

  //                 return(
  //                   <SingleOrderInProgress
  //                     modalShow={handleModalShow}
  //                     communicateClick={handleCommunicateClick}
  //                     checkClick={handleCheckClick}
  //                     cancelClick={handleCancelClick}
  //                     submitClick={handleSubmitClick}
  //                     statusChange={statusChange}
  //                     id={order.id}
  //                     key={order.id}
  //                     fullName={order.fullName}
  //                     phone={order.client.phone}
  //                     vip={order.client.vip}
  //                     warning={order.client.warning}
  //                     notes={JSON.parse(order.extras).notes}
  //                     totalProducts={order.totalProducts}
  //                     totalPrice={order.totalPrice}
  //                     payment={JSON.parse(order.payment)}
  //                     status={order.status}
  //                     created_at={order.created_at}
  //                     hasInstallation={order.hasInstallation}
  //                     status_updated_at={order.status_updated_at}
  //                     showProfit={showProfit}
  //                     showOffers={showOffers}
  //                     products={order.products}
  //                   />
  //                 )
  //               }
  //             }
  //           }
  //       });
  //       setOrderRender(orderComponents);
  //   }else{
  //     const orderComponents = orders.filter(order =>
  //       order.status !== "proxeiro"
  //       ).map(order => {
  //           if (!order.client){
  //             return;
  //           }
  //           if (singleSelections.length===0){
  //           return(
  //             <SingleOrderInProgress
  //               modalShow={handleModalShow}
  //               communicateClick={handleCommunicateClick}
  //               checkClick={handleCheckClick}
  //               cancelClick={handleCancelClick}
  //               submitClick={handleSubmitClick}
  //               statusChange={statusChange}
  //               id={order.id}
  //               key={order.id}
  //               fullName={order.fullName}
  //               phone={order.client.phone}
  //               vip={order.client.vip}
  //               warning={order.client.warning}
  //               notes={JSON.parse(order.extras).notes}
  //               totalProducts={order.totalProducts}
  //               totalPrice={order.totalPrice}
  //               payment={JSON.parse(order.payment)}
  //               status={order.status}
  //               created_at={order.created_at}
  //               hasInstallation={order.hasInstallation}
  //               status_updated_at={order.status_updated_at}
  //               showProfit={showProfit}
  //               showOffers={showOffers}
  //               products={order.products}
  //             />
  //           )}else{
  //             let codeList = [];
  //             let noteList = []
  //             for (let product of order.products){
  //               codeList.push(product.code);
  //               if (JSON.parse(product.product_options).notes){
  //                 noteList.push(JSON.parse(product.product_options).notes);
  //               }else if(JSON.parse(product.product_options).freeText){
  //                 noteList.push(JSON.parse(product.product_options).freeText)
  //               }else{
  //                 noteList.push('');
  //               }
  //             }
  //             const combinedList = codeList.map((element, index) => element + ' '+noteList[index]);
              
  //             if (singleSelections){
  //               if((combinedList.map(item => item.trim()).includes(singleSelections[0].trim())) ||
  //                 singleSelections[0] === order.fullName){

  //                 return(
  //                   <SingleOrderInProgress
  //                     modalShow={handleModalShow}
  //                     communicateClick={handleCommunicateClick}
  //                     checkClick={handleCheckClick}
  //                     cancelClick={handleCancelClick}
  //                     submitClick={handleSubmitClick}
  //                     statusChange={statusChange}
  //                     id={order.id}
  //                     key={order.id}
  //                     fullName={order.fullName}
  //                     phone={order.client.phone}
  //                     vip={order.client.vip}
  //                     warning={order.client.warning}
  //                     notes={JSON.parse(order.extras).notes}
  //                     totalProducts={order.totalProducts}
  //                     totalPrice={order.totalPrice}
  //                     payment={JSON.parse(order.payment)}
  //                     status={order.status}
  //                     created_at={order.created_at}
  //                     hasInstallation={order.hasInstallation}
  //                     status_updated_at={order.status_updated_at}
  //                     showProfit={showProfit}
  //                     showOffers={showOffers}
  //                     products={order.products}
  //                   />
  //                 )
  //               }
  //             }
  //           }
  //       });
  //       setOrderRender(orderComponents);
  //     }
        
  // },[orders, nameSelection, statusChange, singleSelections, modalShow, showProfit, showReady, showOffers])

  useEffect(() => {
  if (!orders.length) return; // Prevents unnecessary execution if no orders

  let tempBalance = 0;
  let filteredOrders = orders;
  if (showOffers) {
    filteredOrders = orders.filter(order => order.status === "proxeiro");
  } else if (showReady) {
    filteredOrders = orders.filter(order => 
        order.status === "ready" || 
        order.status === "communicated" ||
        order.status === "ypoloipo" ||
        order.status === "arn-ypoloipo"
        );
  } else{
    filteredOrders = orders.filter(order => order.status !== "proxeiro")
  }

  const orderComponents = filteredOrders.map(order => {
    if (!order.client) return null;

    let paidSum = 0;
    let payments = [];

    if (order.payment) {
      const parsedPayment = typeof order.payment === "string" ? JSON.parse(order.payment) : order.payment;
      if (parsedPayment && parsedPayment.detailed) {
        payments = typeof parsedPayment.detailed === "string" ? JSON.parse(parsedPayment.detailed) : parsedPayment.detailed;
      }
    }

    payments.forEach(payment => {
      if (payment.amount > 0) {
        paidSum += Number(payment.amount);
      }
    });

    const orderBalance = Math.round((Number(order.totalPrice) - paidSum) * 100) / 100;
    tempBalance += orderBalance;

    if (singleSelections.length === 0) {
      return (
        <SingleOrderInProgress
          key={order.id}
          modalShow={handleModalShow}
          communicateClick={handleCommunicateClick}
          checkClick={handleCheckClick}
          cancelClick={handleCancelClick}
          submitClick={handleSubmitClick}
          statusChange={statusChange}
          id={order.id}
          fullName={order.fullName}
          phone={order.client.phone}
          vip={order.client.vip}
          warning={order.client.warning}
          notes={JSON.parse(order.extras)?.notes || ""}
          totalProducts={order.totalProducts}
          totalPrice={order.totalPrice}
          payment={order.payment}
          status={order.status}
          created_at={order.created_at}
          hasInstallation={order.hasInstallation}
          status_updated_at={order.status_updated_at}
          showProfit={showProfit}
          showOffers={showOffers}
          products={order.products}
        />
      );
    }

    // Handle single selection logic
    const combinedList = order.products.map(product => {
      const productOptions = JSON.parse(product.product_options);
      return `${product.code} ${productOptions.notes || productOptions.freeText || ""}`.trim();
    });

    if (singleSelections.some(selection => combinedList.includes(selection.trim()) || selection === order.fullName)) {
      return (
        <SingleOrderInProgress
          key={order.id}
          modalShow={handleModalShow}
          communicateClick={handleCommunicateClick}
          checkClick={handleCheckClick}
          cancelClick={handleCancelClick}
          submitClick={handleSubmitClick}
          statusChange={statusChange}
          id={order.id}
          fullName={order.fullName}
          phone={order.client.phone}
          vip={order.client.vip}
          warning={order.client.warning}
          notes={JSON.parse(order.extras)?.notes || ""}
          totalProducts={order.totalProducts}
          totalPrice={order.totalPrice}
          payment={order.payment}
          status={order.status}
          created_at={order.created_at}
          hasInstallation={order.hasInstallation}
          status_updated_at={order.status_updated_at}
          showProfit={showProfit}
          showOffers={showOffers}
          products={order.products}
        />
      );
    }

    return null;
  }).filter(Boolean); // Remove `null` values

  setBalance(prevBalance => (prevBalance !== tempBalance ? tempBalance : prevBalance));
  setOrderRender(prevRender => (JSON.stringify(prevRender) !== JSON.stringify(orderComponents) ? orderComponents : prevRender));

}, [orders, singleSelections, showReady, showOffers, showProfit, modalShow, statusChange]);

  const handleShowProfitChange = ()=>{
    setShowProfit(!showProfit);
  }

  const handleShowReadyChange = ()=>{
    setShowReady(!showReady);
  }

  const handleShowOffersChange = ()=>{
    setShowOffers(!showOffers);
  }

    return(
    <Fragment>
    <Card className={`${classes.ordersCard} ${classes.notPrintable}`}>
    <h2 className={classes.notPrintable}>Σε εξέλιξη</h2>
    {notifyModal && <NotifyClient id={currentId} yesClick={handleCommunicateYes} noClick={handleCommunicateNo}/>}
    {completeModal && <CompleteOrder id={currentId} yesClick={handleCheckYes} noClick={handleCheckNo}/>}
    {submitModal && <SubmitOrder id={currentId} yesClick={handleSubmitYes} noClick={handleSubmitNo}/>}
    {cancelModal && <CancelOrder id={currentId} yesClick={handleCancelYes} noClick={handleCancelNo}/>}
    
    {!modalShow && 
    <Fragment>
    <div className={classes.switchesContainer}>
      <div className={classes.switchContainer}>
        <label className={classes.switch}>
          <input type="checkbox" checked={showProfit} onChange={handleShowProfitChange}/>
          <span className={classes.slider} />
        </label>
        <p>Προβολή Κέρδους</p>
      </div>
      <div className={classes.switchContainer}>
        <label className={classes.switch}>
          <input type="checkbox" checked={showReady} onChange={handleShowReadyChange}/>
          <span className={classes.slider} />
        </label>
        <p>Προβολή Έτοιμων</p>
      </div>
      <div className={classes.switchContainer}>
        <p>Παραγγελίες</p>
        <label className={`${classes.switch} ${classes.double}`}>
          <input type="checkbox" checked={showOffers} onChange={handleShowOffersChange}/>
          <span className={classes.slider} />
        </label>
        <p>Προσφορές</p>
      </div>
    </div>
    <p className={`${classes.totalBalance} ${showReady?classes.appear:''}`}>Απομένουν να εισπράξετε: {balance}€</p>
    <div className={classes.searchBlock}>
    <Typeahead
          className={classes.typeaheadSelect}
          id="basic-typeahead-single"
          labelKey="label"
          onChange={setSingleSelections}
          options={options}
          placeholder="Αναζήτηση Πελάτη ή Κωδικού Προϊόντος:"
          selected={singleSelections}
        />
    <Button onClick={handleClearClick}>Καθαρισμός</Button>
    </div>
    <table className={classes.orderTable}>
      <thead>
        <tr>
          <th>#</th>
          <th>Όνομα</th>
          <th>Σημείωση Παραγγελίας</th>
          <th>Υπόλοιπο</th>
          <th>Κατάσταση</th>
          <th>Ημερομηνία Δημιουργίας</th>
          {showProfit&&<th>Κέρδος</th>}
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>{orderRender}</tbody>
    </table>
    </Fragment>
  }
    </Card>
    {modalShow && <ViewOrderInProgress id={currentId} fullName={currentFullName} onClose={onCloseHandler} />}
    </Fragment>
    )
}


export default OrdersInProgress;